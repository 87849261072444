import './App.scss';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import jetTheme from "./components/themes/JetTheme";

import NotFound from "./pages/404";
import Login from "./pages/authentication/Login";
import {AuthProvider} from "./context/AuthContext";
import {ConfigProvider} from "./context/ConfigContext";
import PrivateRoute from "./utils/PrivateRoute";


import {ErrorProvider} from "./context/ErrorContext";
import Controlling from "./pages/controlling/Controlling";
import Reports from "./pages/reports/Reports";
import Report from "./pages/reports/Report";
import ControllingItemNumber from "./pages/controlling/ControllingItemNumber";
import ControllingDetail from "./pages/controlling/ControllingDetail";
import Commission from "./pages/commission/Commission";
import CommissionDetail from "./pages/commission/CommissionDetail";
import TestPage from "./pages/test/TestPage";
import ControllingMain from "./pages/controllingMain/ControllingMain";
import EmployeeEffectivenessDetail from "./pages/employee/EmployeeEffectivenessDetail";
import EmployeeEffectiveness from "./pages/employee/Effectiveness";
import PartnerEffectiveness from "./pages/partner/PartnerEffectiveness";
import PartnerEffectivenessDetail from "./pages/partner/PartnerEffectivenessDetail";
import PartnerCompare from "./pages/partner/PartnerCompare";
import PartnerTrafficEffectiveness from "./pages/partnerTraffic/PartnerEffectiveness";
import PartnerTrafficEffectivenessDetail from "./pages/partnerTraffic/PartnerEffectivenessDetail";
import FinishedReports from "./pages/finishedReports/FinishedReports";
import TicketReport from "./pages/finishedReports/ticketReport/TicketReport";
import SavedCommissions from "./pages/commission/SavedCommission";
import SavedCommissionDetail from "./pages/commission/SavedCommissionDetail";
import SelectView from "./pages/partner/SelectView";
import CompanyGroups from "./pages/partner/companyGroups/CompanyGroups";
import CompanyGroupDetail from "./pages/partner/companyGroups/CompanyGroupDetail";
import GroupCompare from "./pages/partner/companyGroups/GroupCompare";
import Home from "./pages/home/Home";


function App() {

  return <ThemeProvider theme={jetTheme}>
    <ConfigProvider>
      <ErrorProvider>
        <Router>
          <AuthProvider>
            <Switch>
              <PrivateRoute path="/" component={Home} exact/>
              <PrivateRoute path="/controlling" component={Controlling} exact requiredPermissions={['view_generalledgeranalytics']}/>
              <PrivateRoute path="/item-number" component={ControllingItemNumber} requiredPermissions={['view_generalledgeranalytics']} exact/>
              <PrivateRoute path="/item-number/:slug" component={ControllingDetail} requiredPermissions={['view_generalledgeranalytics']} exact/>
              <PrivateRoute path="/report" component={Reports} requiredPermissions={['view_report']} exact/>
              <PrivateRoute path="/finished-report" component={FinishedReports} requiredPermissions={['view_report']} exact/>
              <PrivateRoute path="/report/:id" component={Report} requiredPermissions={['view_report']} exact/>
              <PrivateRoute path="/ticket-report" component={TicketReport} requiredPermissions={['view_report']} exact/>
              <PrivateRoute path="/commission" component={Commission} requiredPermissions={['view_customuser']} exact/>
              <PrivateRoute path="/commission/:id" component={CommissionDetail} requiredPermissions={['view_customuser']} exact/>
              <PrivateRoute path="/saved-commissions" component={SavedCommissions} requiredPermissions={['view_savedcommission']} exact/>
              <PrivateRoute path="/saved-commission/:id" component={SavedCommissionDetail} requiredPermissions={['view_savedcommission']} exact/>
              <PrivateRoute path="/controlling-main" component={ControllingMain} requiredPermissions={['view_generalledgeranalytics']} exact/>
              <PrivateRoute path="/select-view" component={SelectView} requiredPermissions={['view_generalledgeranalytics']} exact/>
              <PrivateRoute path="/employee-effectiveness" component={EmployeeEffectiveness} requiredPermissions={['view_generalledgeranalytics']} exact/>
              <PrivateRoute path="/company-groups" component={CompanyGroups} requiredPermissions={['view_companygroup']} exact/>
              <PrivateRoute path="/company-groups/:id" component={CompanyGroupDetail} requiredPermissions={['view_companygroup']} exact/>
              <PrivateRoute path="/employee-effectiveness/:id" component={EmployeeEffectivenessDetail} requiredPermissions={['view_customuser']} exact/>
              <PrivateRoute path="/partner-effectiveness" component={PartnerEffectiveness} requiredPermissions={['view_partner']} exact/>
              <PrivateRoute path="/partner-effectiveness/:id" component={PartnerEffectivenessDetail} requiredPermissions={['view_partner']} exact/>
              <PrivateRoute path="/partner-compare/:first/:second" component={PartnerCompare} requiredPermissions={['view_partner']} exact/>
              <PrivateRoute path="/group-compare/:first/:second" component={GroupCompare} requiredPermissions={['view_partner']} exact/>
              <PrivateRoute path="/partner-traffic-effectiveness" component={PartnerTrafficEffectiveness} requiredPermissions={['view_partner']} exact/>
              <PrivateRoute path="/partner-traffic-effectiveness/:id" component={PartnerTrafficEffectivenessDetail} requiredPermissions={['view_partner']} exact/>
              {/*<PrivateRoute path="/test" component={TestPage} exact/>*/}

              <Route path="/login" component={Login} exact/>

              <Route component={NotFound}/>
            </Switch>
          </AuthProvider>
        </Router>
      </ErrorProvider>
    </ConfigProvider>
  </ThemeProvider>
}

export default App;