import {Stack, TextField, Typography} from "@mui/material";

const ModifierData = ({reference, modifier}) => {
  return <form ref={reference}>
      <Stack spacing={2}>
          <Typography variant='caption'>Elnevezés</Typography>
          <TextField variant='standard' name='name' defaultValue={modifier?.name || ''}/>
          <Typography variant='caption'>Megjegyzés</Typography>
          <TextField variant='standard' name='comment' defaultValue={modifier?.comment || ''}/>
          <Typography variant='caption'>Érték</Typography>
          <TextField variant='standard' name='amount' defaultValue={modifier?.amount || 0} type='number'/>
      </Stack>
  </form>
}

export default ModifierData