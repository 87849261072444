import {FormControl, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {hu} from "date-fns/locale";

const ControllingModifierData = ({reference, modifier}) => {
    const [date, setDate] = useState(modifier?.date || new Date())
    const [selectedClass, setSelectedClass] = useState(modifier?.mirr_class || 5)
  return <form ref={reference}>
      <Stack spacing={2}>
          <Typography variant='caption'>Megnevezés</Typography>
          <TextField variant='standard' name='name' defaultValue={modifier?.name || ''} required/>
          <Typography variant='caption'>Összeg</Typography>
          <TextField variant='standard' name='amount' defaultValue={modifier?.amount || 0} type='number' required/>
          <Typography variant='caption'>Számlaosztály</Typography>
          <FormControl>
              <Select
                  id="class-select"
                  value={selectedClass}
                  variant='standard'
                  onChange={e => setSelectedClass(e.target.value)}
              >
                  <MenuItem value={5}>5-ös számlaosztály</MenuItem>
                  <MenuItem value={8}>8-as számlaosztály</MenuItem>
                  <MenuItem value={9}>9-es számlaosztály</MenuItem>
              </Select>
          </FormControl>
          <input type="hidden" value={selectedClass} name='mirr_class'/>
          <Typography variant='caption'>Dátum</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hu}>
              <DatePicker
                  inputFormat="yyyy-MM-dd"
                  mask='____-__-__'
                  value={date}
                  onChange={(v) => setDate(v)}
                  renderInput={(params) => <TextField name='date' variant='standard' {...params} required />}
              />
          </LocalizationProvider>
      </Stack>
  </form>
}

export default ControllingModifierData