import {Avatar, Card, CardActions, CardContent, Grid, IconButton, Stack, Typography, useTheme} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, {useContext, useEffect, useRef, useState} from "react";
// import BasicSpeedDial from "../../components/BasicSpeedDial";
import AuthContext from "../../context/AuthContext";
import DinoDialog from "../../components/Dialog";
import useCallDataApi from "../../hooks/data";
import Button from "@mui/material/Button";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import Loading from "../../components/Loading";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ResponsiveDrawer from "../../components/AuthDrawer";
import {getFormData} from "../../utils/formUtils";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';

const FinishedReports = () => {
    document.title = 'Kész riportok'
    const {permissions} = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [reports, setReports] = useState([])
    const {enqueueSnackbar} = useSnackbar()
    const history = useHistory()
    const theme = useTheme()


    return <ResponsiveDrawer label='Kész riportok'>
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        <CardContent>
                            <Typography variant="headline6" color={theme.palette.primary[600]}>
                                Repjegy kimutatás
                            </Typography>

                        </CardContent>
                        <CardActions>
                            <Button size="medium" variant='contained' color='success'
                                    onClick={() => history.push(`ticket-report/`)}>Megnyitás</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>

        <Loading isLoading={loading}/>
    </ResponsiveDrawer>
}

export default FinishedReports