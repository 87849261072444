import AuthDrawer from "../../components/AuthDrawer";
import useCallDataApi from "../../hooks/data";
import {useEffect, useState} from "react";
import {Avatar, Card, CardActions, CardContent, Grid, Stack, Typography, useTheme} from "@mui/material";
import Loading from "../../components/Loading";
import Button from "@mui/material/Button";
import {stringAvatar} from "../../utils/miscUtils";
import {useHistory} from "react-router-dom";

const EmployeeEffectiveness = () => {
    const {getData: getUsers} = useCallDataApi('users')
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)
    const theme = useTheme()
    const history = useHistory()

    document.title = 'Ügyintézői eredményesség'

    useEffect(() => {
        getUsers()
            .then(u => setUsers(u))
            .finally(() => setLoading(false))
    }, [])

    const handleOpenPerson = (user) => {
        history.push(`/employee-effectiveness/${user?.id}`)
    }

    return <AuthDrawer label='Ügyintézői Eredményesség'>
        <Grid container spacing={2}>
            {users?.map(u => <Grid key={u.id} item xs={12} md={6} xl={6}>
                <Card>
                    <CardContent>
                        <Stack direction='row' spacing={4}>
                            <Avatar {...stringAvatar(u?.name)} sx={{width: 70, height: 70}}/>
                            <Stack spacing={1}>
                                <Typography variant="headline6" color={theme.palette.primary[600]}>
                                    {u?.name}
                                </Typography>
                                <Typography variant="button" color={theme.palette.secondary[600]}>
                                    {u?.email} | {u?.department?.name}
                                </Typography>
                            </Stack>
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <Button variant='outlined' onClick={() => handleOpenPerson(u)}>Megnyitás</Button>
                    </CardActions>
                </Card>
            </Grid>)}
        </Grid>

        <Loading isLoading={loading}/>

    </AuthDrawer>
}

export default EmployeeEffectiveness