import {FormControl, MenuItem, Select, Stack, Typography} from "@mui/material";
import {useState} from "react";

const GroupData = ({reference, group}) => {
    const [operator, setOperator] = useState(group?.operator || 'AND');

    const handleChange = (event) => {
        setOperator(event.target.value);
    };

    return <form ref={reference}>
        <Stack spacing={2}>
            <Typography variant='p'>Csoprt művelet</Typography>
            <FormControl fullWidth>
                <Select
                    id="group-data-select"
                    value={operator}
                    label="Csoprt művelet"
                    name='operator'
                    onChange={handleChange}
                >
                    <MenuItem value='AND'>AND</MenuItem>
                    <MenuItem value='OR'>OR</MenuItem>
                </Select>
            </FormControl>
        </Stack>
    </form>
}

export default GroupData