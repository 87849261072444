import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {Drawer, useTheme} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {useHistory} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import logo from "../assets/logos/jet-travel-logo-white 1.png"

const pages = [
    {name: 'Kezdőlap', link: '/'},
    {name: 'Bejelentkezés', link: '/login'},
    {name: 'Regisztráció', link: '/register'},
]

function DinoAppBar() {

    const theme = useTheme();
    const history = useHistory()

    const buttonTheme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: 'white', backgroundColor: theme.palette.primary.main, '&:hover': {
                            backgroundColor: theme.palette.secondary.main,
                        },
                        borderRadius: 30,
                        textTransform: 'capitalize',
                        width: 'fit-content',
                        fontFamily: 'Archivo',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '18px',
                        padding: '15px 16px 15px 12px',
                        height: 'fit-content',
                        margin: '14px',
                    }
                }
            }
        },
    })


    const style = {
        background: theme.palette.background.paper,
        color: theme.palette.text.secondary,
    }

    const menuIconStyle = {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '30px',
        color: 'white',
    }

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const toggleNavMenu = (event) => {
        setAnchorElNav(!Boolean(anchorElNav) ? event.currentTarget : null);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -117;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    }

    return (
        <AppBar position="sticky" sx={style}>
            <Container maxWidth="xl">

                <Toolbar disableGutters sx={{paddingBottom: '10px', paddingTop: '10px', justifyContent: 'center', alignItems: 'center'}}>
                    <Box sx={{flexGrow: 0}}>
                        <img src={logo} alt="Jettravel logo" width="100px"/>
                    </Box>
                    {/*<Box sx={{display: {xs: 'flex', md: 'none'}, marginLeft: 'auto', zIndex: '11'}}>*/}
                    {/*    <div style={menuIconStyle}>*/}
                    {/*        <IconButton*/}
                    {/*            size="large"*/}
                    {/*            aria-label="account of current user"*/}
                    {/*            aria-controls="menu-appbar"*/}
                    {/*            aria-haspopup="true"*/}
                    {/*            onClick={toggleNavMenu}*/}
                    {/*            color="inherit"*/}
                    {/*        >*/}
                    {/*            {!anchorElNav ? <MenuIcon/> : <CloseIcon/>}*/}
                    {/*        </IconButton>*/}
                    {/*    </div>*/}
                    {/*    <Drawer*/}
                    {/*        anchor={'top'}*/}
                    {/*        open={Boolean(anchorElNav)}*/}
                    {/*        onClose={toggleNavMenu}*/}
                    {/*        ModalProps={{*/}
                    {/*            keepMounted: true,*/}
                    {/*        }}*/}
                    {/*        BackdropProps={{invisible: true}}*/}
                    {/*        sx={{*/}
                    {/*            '& .MuiDrawer-paper': {*/}
                    {/*                boxSizing: 'border-box',*/}
                    {/*                width: '100%',*/}
                    {/*                height: '80%',*/}
                    {/*                marginTop: '56px'*/}
                    {/*            },*/}
                    {/*            display: 'flex',*/}
                    {/*            justifyContent: 'center',*/}
                    {/*            alignItems: 'center',*/}
                    {/*            marginLeft: 'auto',*/}
                    {/*            marginRight: 'auto',*/}
                    {/*            zIndex: '10'*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <div style={{*/}
                    {/*            display: 'flex',*/}
                    {/*            flexDirection: 'column',*/}
                    {/*            justifyContent: 'center',*/}
                    {/*            alignItems: 'center',*/}
                    {/*            width: '100%',*/}
                    {/*            height: '100%',*/}
                    {/*            margin: 'auto',*/}
                    {/*            gap: '48px'*/}
                    {/*        }}>*/}
                    {/*            {pages.map((page) => (*/}
                    {/*                <MenuItem key={page.name + 'mobile'} onClick={handleCloseNavMenu}>*/}
                    {/*                    <HashLink to={page.link} scroll={el => scrollWithOffset(el)}*/}
                    {/*                              style={{textDecoration: 'none', color: theme.palette.primary[600]}}>*/}
                    {/*                        <Typography textAlign="center" variant="subtitle2">{page.name}</Typography>*/}
                    {/*                    </HashLink>*/}
                    {/*                </MenuItem>*/}
                    {/*            ))}*/}
                    {/*        </div>*/}

                    {/*    </Drawer>*/}

                    {/*</Box>*/}

                    {/*<Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, justifyContent: 'flex-end'}}>*/}
                    {/*    <HashLink to="/" scroll={el => scrollWithOffset(el)}*/}
                    {/*              style={{textDecoration: 'none', display: 'flex'}}>*/}
                    {/*        <Button sx={{my: 2, display: 'block'}}*/}
                    {/*                style={{alignSelf: "center", color: theme.palette.primary[700]}}>*/}
                    {/*            Kezdőlap*/}
                    {/*        </Button>*/}
                    {/*    </HashLink>*/}
                    {/*    <Button variant='outlined' sx={{margin: '14px'}} onClick={() => window.location.href = '/login'}>Bejelentkezés</Button>*/}
                    {/*    <Button variant='contained' sx={{margin: '14px'}} onClick={() => window.location.href = '/register'}>Regisztráció</Button>*/}
                    {/*</Box>*/}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default DinoAppBar;