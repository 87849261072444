import {createContext, useContext, useState} from "react";
import DinoDialog from "../components/Dialog";
import ConfigContext from "./ConfigContext";
import {Stack, Typography} from "@mui/material";

const ErrorContext = createContext();

export default ErrorContext;

export const ErrorProvider = ({ children }) => {
    const {MAX_IMG_SIZE} = useContext(ConfigContext)
    const [subscriptionError, setSubscriptionError] = useState(false)
    const [planError, setPlanError] = useState(false)
    const [unexpectedError, setUnexpectedError] = useState(false)
    const [networkError, setNetworkError] = useState(false)
    const [permissionError, setPermissionError] = useState(false)
    const [pictureError, setPictureError] = useState(false)
    const [expectedError, setExpectedError] = useState({error: false, message: []})

    const contextData = {
        setSubscriptionError: setSubscriptionError,
        setPlanError: setPlanError,
        setUnexpectedError: setUnexpectedError,
        setNetworkError: setNetworkError,
        setPermissionError: setPermissionError,
        setPictureError: setPictureError,
        setExpectedError: setExpectedError
    }

    return <ErrorContext.Provider value={contextData}>
        {children}
        <DinoDialog open={subscriptionError}
                    title='Lejárt az előfizetése!'
                    subtitle='Ujítsa meg!'
            // actions={deleteActions}
                    handleClose={() => setSubscriptionError(false)}>
        </DinoDialog>

        <DinoDialog open={planError}
                    title='Az előfizetési csomag nem tartalmazz ezt a funkciót!'
                    subtitle='Váltson előfizetési csomagot'
            // actions={deleteActions}
                    handleClose={() => setPlanError(false)}>
        </DinoDialog>

        <DinoDialog open={unexpectedError}
                    title='Váratlan hiba!'
                    subtitle='Kérjük töltse újra az oldalt'
            // actions={deleteActions}
                    handleClose={() => setUnexpectedError(false)}>
        </DinoDialog>

        <DinoDialog open={networkError}
                    title='A szolgáltatás jelenleg nem elérhető!'
                    subtitle='Kérjük látogasson vissza később'
            // actions={deleteActions}
                    handleClose={() => setNetworkError(false)}>
        </DinoDialog>

        <DinoDialog open={permissionError}
                    title='Jogosultság hiba!'
                    subtitle='Nincs jogosultsága a művelet elvégzéséhez!'
            // actions={deleteActions}
                    handleClose={() => setPermissionError(false)}>
        </DinoDialog>

        <DinoDialog open={pictureError}
                    title='Kötelező képet feltölteni!'
                    subtitle={`Amennyiben nem sikerült a feltöltés, kérjük figyelj rá, hogy a kép mérete nem haladhatja meg a ${MAX_IMG_SIZE}MB méretet.`}
            // actions={deleteActions}
                    handleClose={() => setPictureError(false)}>
        </DinoDialog>

        <DinoDialog open={expectedError.error}
                    title='Hiba!'
                    subtitle='Kérjük javítsa az alábbi hibákat!'
            // actions={deleteActions}
                    handleClose={() => setExpectedError({error: false, message: []})}>
            <Stack spacing={1}>
                {expectedError.message?.map(e => <Typography key={`err_${e}`} variant='body2' color='#ff0000'>{e}</Typography>)}
            </Stack>
        </DinoDialog>
    </ErrorContext.Provider>
}