import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import useCallDataApi from "../../hooks/data";
import {Card, CardActions, CardContent, Chip, Grid, Stack, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import DinoDialog from "../../components/Dialog";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReportPartData from "./ReportPartData";
import {useSnackbar} from "notistack";

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import Loading from "../../components/Loading";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ResponsiveDrawer from "../../components/AuthDrawer";
import thousandSeparator from "../../utils/NumberUtils";
import {getFormData} from "../../utils/formUtils";
import GoogleBarChart from "../../components/charts/GoogleBarChart";
import QueryData from "./QueryData";
import FilterArea from "./FilterArea";
import ReportCard from "./ReportCard";


const Report = () => {
    const params = useParams()
    const {getData: fetchReport} = useCallDataApi('report-system/report')
    const {
        postData: newReportPart,
        updateData: updateReportPart,
        deleteData: deleteReportPart
    } = useCallDataApi('report-system/report-part')
    const {
        getData: fetchQueries,
        postData: newQuery,
        updateData: updateQuery,
        deleteData: deleteQuery,
        getFile: getReportCsv
    } = useCallDataApi('report-system/query')
    const [report, setReport] = useState({results: {}})
    const [results, setResults] = useState([])
    // const [filters, setFilters] = useState({})
    const [fields, setFields] = useState([])
    const [current, setCurrent] = useState(1)

    const [isNewQueryDialogOpen, setNewQueryDialogOpen] = useState(false)
    const [isEditQueryDialogOpen, setEditQueryDialogOpen] = useState(false)
    const [isDeleteQueryDialogOpen, setDeleteQueryDialogOpen] = useState(false)

    const [isNewReportPartDialogOpen, setNewReportPartDialogOpen] = useState(false)
    const [isEditReportPartDialogOpen, setEditReportPartDialogOpen] = useState(false)
    const [isDeleteReportPartDialogOpen, setDeleteReportPartDialogOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [queries, setQueries] = useState([])
    const reportPartForm = useRef()
    const queryForm = useRef(null)
    const queryBuilderRef = useRef(null)
    const queryBuilderExcludeRef = useRef(null)
    const {enqueueSnackbar} = useSnackbar()
    const history = useHistory()

    useEffect(() => {
        const getData = async () => {
            const [r, p, q] = await Promise.all([
                fetchReport(`${params?.id}/get_report_parts`),
                // fetchReport(`${params?.id}/get_fields`),
                fetchReport(params?.id),
                fetchQueries()
            ])
            if (r) {
                setResults(r)
                console.log(r)
                // setFilters(r?.filter)
            }
            // if (f) setFields(f)
            if (p) {
                setReport(p)
                document.title = `Riport | ${p?.name}`
            }
            if (q) setQueries(q)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const findReportPart = (id) => results.find(r => r?.id === id)

    const createQuery = () => {
        const formData = getFormData(queryForm.current)
        newQuery('', formData).then(n => {
            setQueries([...queries, n])
            enqueueSnackbar('Szűrés elkészítve!', {variant: 'success'})
        })
    }

    const editQuery = () => {
        const formData = getFormData(queryForm.current)
        const newVar = {
            ...formData,
            filter_json: queryBuilderRef.current.get(),
            exclude_json: queryBuilderExcludeRef.current.get()
        };
        updateQuery(current + '/', newVar).then(editedReport => {
            const editedReportList = queries.map(report => {
                if (current === report.id) return {...report, ...editedReport}
                return report;
            });
            setQueries(editedReportList)
            enqueueSnackbar('Szűrés szerkesztve!', {variant: 'success'})
        })
    }

    const destroyQuery = () => {
        deleteQuery(current).then(() => {
            setQueries(queries.filter(r => r.id !== current))
            enqueueSnackbar('Szűrés törölve!', {variant: 'success'})
        })
    }

    const createReportPart = () => {
        const formData = getFormData(reportPartForm.current)
        newReportPart('', {...formData, report: params?.id}).then(n => {
            setResults([...results, n])
            enqueueSnackbar('Kimutatás hozzáadva!', {variant: 'success'})
        })
    }

    const destroyReportPart = () => {
        deleteReportPart(current).then(() => {
            setResults(results.filter(r => r.id !== current))
            enqueueSnackbar('Kimutatás törölve!', {variant: 'success'})
        })
    }

    const editReportPart = () => {
        const formData = getFormData(reportPartForm.current)
        const fields = {fields: [formData.fields]}
        updateReportPart(current, {...formData, fields: fields, report: params?.id}).then(editedReportPart => {
            const editedReportPartList = results.map(reportPart => {
                if (current === reportPart.id) return {...reportPart, ...editedReportPart}
                return reportPart;
            });
            setResults(editedReportPartList)
            enqueueSnackbar('Kimutatás szerkesztve!', {variant: 'success'})
        })
    }


    const downloadCsv = (id) => {
        getReportCsv(`${id}/csv_view`, 'csv', report?.name).then(() => enqueueSnackbar('CSV letöltve!', {variant: 'success'}))
    }

    const findReport = id => queries.find(r => r?.id === id)

    let buttons = [{
        name: 'Új kimutatás',
        props: {
            variant: 'contained',
            endIcon: <AddCircleRoundedIcon/>,
            onClick: () => setNewReportPartDialogOpen(true)
        }
    }, {
        name: 'Új szűrés',
        props: {
            variant: 'contained',
            endIcon: <AddCircleRoundedIcon/>,
            onClick: () => setNewQueryDialogOpen(true)
        }
    }];

    return <ResponsiveDrawer label={report?.name} buttons={buttons}>
        <div>
            <Stack marginBottom={2} direction='row'>
                <Button variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>} onClick={() => history.push('/report')}>Vissza</Button>
            </Stack>

            <FilterArea filters={queries} onEditClick={id => {
                setCurrent(id)
                setEditQueryDialogOpen(true)
            }} onDeleteClick={id => {
                setCurrent(id)
                setDeleteQueryDialogOpen(true)
            }} onDownloadClick={id => downloadCsv(id)}/>



            <Grid container spacing={2} marginTop={2}>
                {results?.map((r, i) => <Grid key={`report_${i}`} item xs={12} lg={12}>
                    <ReportCard
                        r={r}
                        setCurrent={setCurrent}
                        setDeleteReportPartDialogOpen={setDeleteReportPartDialogOpen}
                        setEditReportPartDialogOpen={setEditReportPartDialogOpen}
                    />
                </Grid>)}
            </Grid>
        </div>

        <Loading isLoading={loading}/>

        <DinoDialog title='Új kimutatás'
                    subtitle='Új kimutatás'
                    open={isNewReportPartDialogOpen}
                    handleClose={() => setNewReportPartDialogOpen(false)}
                    actions={<>
                        <Button variant='outlined' style={{padding: 15}}
                                onClick={() => setNewReportPartDialogOpen(false)}>mégsem</Button>
                        <Button variant='contained' style={{padding: 15}} onClick={() => {
                            setNewReportPartDialogOpen(false)
                            createReportPart()
                        }} endIcon={<AddIcon/>}>Hozzáadás</Button>
                    </>}>
            <ReportPartData queries={queries} reference={reportPartForm} fields={fields}/>
        </DinoDialog>

        <DinoDialog title='Kimutatás szerkesztése'
                    subtitle='Kimutatás szerkesztése'
                    open={isEditReportPartDialogOpen}
                    handleClose={() => setEditReportPartDialogOpen(false)}
                    actions={<>
                        <Button variant='outlined' style={{padding: 15}}
                                onClick={() => setEditReportPartDialogOpen(false)}>mégsem</Button>
                        <Button variant='contained' style={{padding: 15}} onClick={() => {
                            setEditReportPartDialogOpen(false)
                            editReportPart()
                        }} endIcon={<EditIcon/>}>Szerkesztés</Button>
                    </>}>
            <ReportPartData queries={queries} reference={reportPartForm} reportPart={findReportPart(current)} fields={fields}/>
        </DinoDialog>

        <DinoDialog title='Kimutatás törlése'
                    subtitle='Kimutatás törlése'
                    open={isDeleteReportPartDialogOpen}
                    handleClose={() => setDeleteReportPartDialogOpen(false)}
                    actions={<>
                        <Button variant='outlined' style={{padding: 15}}
                                onClick={() => setDeleteReportPartDialogOpen(false)}>mégsem</Button>
                        <Button variant='contained' color='error' style={{padding: 15}} onClick={() => {
                            setDeleteReportPartDialogOpen(false)
                            destroyReportPart()
                        }} endIcon={<DeleteIcon/>}>Törlés</Button>
                    </>}/>

        <DinoDialog title='Szűrés hozzáadása'
                    subtitle='Írd be mi legyen a neve és melyik táblából szűrjön'
                    open={isNewQueryDialogOpen}
                    handleClose={() => setNewQueryDialogOpen(false)}
                    actions={<>
                        <Button variant='outlined' style={{padding: 15}}
                                onClick={() => setNewQueryDialogOpen(false)}>mégsem</Button>
                        <Button variant='contained' style={{padding: 15}} onClick={() => {
                            setNewQueryDialogOpen(false)
                            createQuery()
                        }} endIcon={<EditIcon/>}>Szerkesztés</Button>
                    </>}>
            <QueryData reference={queryForm} queryBuilderRef={queryBuilderRef}
                       queryBuilderExcludeRef={queryBuilderExcludeRef}/>
        </DinoDialog>

        <DinoDialog title='Szűrés szerkesztése'
                    subtitle='Vátoztasd meg a szűrés feltételeit'
                    open={isEditQueryDialogOpen}
                    handleClose={() => setEditQueryDialogOpen(false)}
                    actions={<>
                        <Button variant='outlined' style={{padding: 15}}
                                onClick={() => setEditQueryDialogOpen(false)}>mégsem</Button>
                        <Button variant='contained' style={{padding: 15}} onClick={() => {
                            setEditQueryDialogOpen(false)
                            editQuery()
                        }} endIcon={<EditIcon/>}>Szerkesztés</Button>
                    </>}>
            <QueryData reference={queryForm} report={findReport(current)} queryBuilderRef={queryBuilderRef}
                       queryBuilderExcludeRef={queryBuilderExcludeRef}/>
        </DinoDialog>

        <DinoDialog title='Szűrés törlése'
                    subtitle='Ez a művelet nem vonható vissza, ha valamelyik kimutatás használja ezt a szűrést, az is törlődni fog!'
                    open={isDeleteQueryDialogOpen}
                    handleClose={() => setDeleteQueryDialogOpen(false)}
                    actions={<>
                        <Button variant='outlined' style={{padding: 15}}
                                onClick={() => setDeleteQueryDialogOpen(false)}>mégsem</Button>
                        <Button variant='contained' color='error' style={{padding: 15}} onClick={() => {
                            setDeleteQueryDialogOpen(false)
                            destroyQuery()
                        }} endIcon={<DeleteIcon/>}>Törlés</Button>
                    </>}/>
    </ResponsiveDrawer>
}

export default Report