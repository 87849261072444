import AuthDrawer from "../../components/AuthDrawer";
import {
    Avatar,
    Box,
    Card,
    CardActions,
    CardContent,
    FormControl,
    Grid, IconButton, Menu, MenuItem, Select,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {stringAvatar} from "../../utils/miscUtils";
import Button from "@mui/material/Button";
import Loading from "../../components/Loading";
import {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import useCallDataApi from "../../hooks/data";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {hu} from "date-fns/locale";
import {format} from "date-fns";
import thousandSeparator from "../../utils/NumberUtils";


const PartnerTrafficEffectiveness = () => {
    const queryParams = JSON.parse(localStorage.getItem('partnerQuery') || "{}")
    const today = new Date()
    const {getData: fetchPartners, postData: postPartners} = useCallDataApi('partners')
    const [compare, setCompare] = useState(null)
    const [loading, setLoading] = useState(true)
    const [partners, setPartners] = useState([])
    const [pageNumber, setPageNumber] = useState(queryParams?.page_number || 1)
    const [dataLength, setDataLength] = useState(0)
    const [searchQuery, setSearchQuery] = useState(queryParams?.search_query || '')
    const [fromDate, setFromDate] = useState(new Date(queryParams?.start_date ? queryParams?.start_date : `${today.getFullYear()}-01-01`))
    const [endDate, setEndDate] = useState(queryParams?.end_date ? new Date(queryParams?.end_date) : today)
    const [selectedMode, setSelectedMode] = useState(queryParams?.mode || 'person')
    const theme = useTheme()
    const history = useHistory()
    document.title = 'Ügyfél forgalom'

    const getPartners = () => {
        setLoading(true)
        postPartners(`paginated_partners/?page=${pageNumber}`, {
            search_query: searchQuery,
            start_date: format(fromDate, 'yyyy-MM-dd'),
            end_date: format(endDate, 'yyyy-MM-dd'),
            mode: selectedMode,
            view: 'traffic'
        })
            .then(p => {
                setPartners(p)
                // setDataLength(p?.count)
            })
            .catch(e => {
                console.log(e)
                setPartners([])
                setPageNumber(1)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getPartners()
        localStorage.setItem('partnerQuery', JSON.stringify({
            search_query: searchQuery,
            start_date: format(fromDate, 'yyyy-MM-dd'),
            end_date: format(endDate, 'yyyy-MM-dd'),
            mode: selectedMode,
            view: 'traffic',
            page_number: pageNumber
        }))
    }, [pageNumber, searchQuery, fromDate, endDate, selectedMode])

    const handleOpen = (partner) => {
        history.push(`/partner-traffic-effectiveness/${partner?.id}`)
    }

    const Navigation = ({page_number}) => {
        const maxPageSize = Math.floor(dataLength / 100) + 1;
        return <Stack direction='row' sx={{gap: 2}} alignItems='center' justifyContent='center' marginBottom={2}
                      marginTop={2}>
            <Button size='small' variant='contained' startIcon={<ArrowBackIosNewRoundedIcon/>}
                    disabled={pageNumber === 1} onClick={() => setPageNumber(1)}>Első</Button>
            <Button size='small' variant='contained' startIcon={<ArrowBackIosNewRoundedIcon/>}
                    disabled={pageNumber === 1} onClick={() => setPageNumber(prev => prev - 1)}>Előző</Button>
            <Typography>{page_number} / {maxPageSize} oldal</Typography>
            <Button size='small' variant='contained' endIcon={<ArrowForwardIosRoundedIcon/>}
                    disabled={pageNumber === maxPageSize}
                    onClick={() => setPageNumber(prev => prev + 1)}>Következő</Button>
            <Button size='small' variant='contained' endIcon={<ArrowForwardIosRoundedIcon/>}
                    disabled={pageNumber === maxPageSize} onClick={() => setPageNumber(maxPageSize)}>Utolsó</Button>
        </Stack>
    }

    const Search = () => {
        return <Stack direction='row' alignItems='center' gap={2}>
            <TextField variant='standard' placeholder='Név tartalmazza...' value={searchQuery}
                       onChange={e => setSearchQuery(e?.target?.value)}/>
            {/*<Button variant='contained' onClick={() => setSearchQuery('')} startIcon={<SearchOffRoundedIcon/>}>Keresés törlése</Button>*/}
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hu}>
                <Stack spacing={2} direction='row' alignItems='center'>
                    <DatePicker
                        inputFormat="yyyy-MM-dd"
                        mask='____-__-__'
                        value={fromDate}
                        onChange={(v) => setFromDate(v)}
                        renderInput={(params) => <TextField name='from_date' variant='standard' {...params} />}
                    />
                    <Typography variant='body1' color='#38556A'>-</Typography>
                    <DatePicker
                        inputFormat="yyyy-MM-dd"
                        mask='____-__-__'
                        value={endDate}
                        onChange={(v) => setEndDate(v)}
                        renderInput={(params) => <TextField name='until_date' variant='standard' {...params} />}
                    />
                </Stack>
            </LocalizationProvider>
            <FormControl>
                <Select
                    id="company-select"
                    value={selectedMode}
                    onChange={e => setSelectedMode(e?.target?.value)}
                    variant='standard'
                >
                    <MenuItem value='person'>Magánszemély</MenuItem>
                    <MenuItem value='non-person'>Nem magánszemély</MenuItem>
                </Select>
            </FormControl>

            {/*<Button variant='outlined' startIcon={<SearchRoundedIcon/>}>Keresés</Button>*/}
        </Stack>
    }

    const Partner = ({partner}) => {
        const [anchorEl, setAnchorEl] = useState(null)
        const open = Boolean(anchorEl)
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget)
        }
        const handleClose = () => {
            setAnchorEl(null)
        }

        const selectForCompare = (id) => {
            setCompare(id)
            handleClose()
        }

        const compareWithSelected = (id) => {
            setCompare(null)
            history.push(`/partner-compare/${compare.id}/${id}`)
            handleClose()
        }
        return <Card>
            <CardContent>
                <Stack direction='row' spacing={4}>
                    {/*<Avatar {...stringAvatar(u?.uf_name)} sx={{width: 70, height: 70}}/>*/}
                    <Stack direction='row' justifyContent='space-between' width='100%'>
                        <Stack spacing={1}>
                            <Typography variant="headline6" color={theme.palette.primary[600]}>
                                {partner?.uf_name}
                            </Typography>

                        </Stack>
                        {/*<div>*/}
                        {/*    <IconButton*/}
                        {/*        id="basic-button"*/}
                        {/*        aria-controls={open ? 'basic-menu' : undefined}*/}
                        {/*        aria-haspopup="true"*/}
                        {/*        aria-expanded={open ? 'true' : undefined}*/}
                        {/*        onClick={handleClick}*/}
                        {/*    >*/}
                        {/*        <MoreVertIcon/>*/}
                        {/*    </IconButton>*/}
                        {/*    <Menu*/}
                        {/*        id="basic-menu"*/}
                        {/*        anchorEl={anchorEl}*/}
                        {/*        open={open}*/}
                        {/*        onClose={handleClose}*/}
                        {/*        MenuListProps={{*/}
                        {/*            'aria-labelledby': 'basic-button',*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <MenuItem onClick={() => selectForCompare({id: partner?.uf_code, name: partner?.uf_name})}>Kiválasztás összehasonlításhoz</MenuItem>*/}
                        {/*        /!*<MenuItem disabled={compare === null} onClick={() => compareWithSelected(partner?.uf_code)}>Összehasonlítás ezzel: {compare?.name}</MenuItem>*!/*/}
                        {/*    </Menu>*/}
                        {/*</div>*/}
                    </Stack>
                </Stack>
            </CardContent>
            <CardActions>
                <Button variant='outlined' onClick={() => handleOpen(partner)}>Megnyitás</Button>
            </CardActions>
        </Card>;
    }

    return <AuthDrawer label='Ügyfél forgalom'>
        <Stack direction='row' justifyContent='space-between'
               sx={{background: '#fff', borderRadius: '8px', padding: '5px'}} marginBottom={2}>
            {Search()}
        </Stack>
        {/*<Stack direction='row' justifyContent='center'*/}
        {/*       sx={{background: '#fff', borderRadius: '8px', padding: '5px'}} marginBottom={2}>*/}
        {/*    <Navigation page_number={pageNumber}/>*/}
        {/*</Stack>*/}
        {partners?.length === 0 && <Stack alignItems='center' justifyContent='center' marginBottom={2}>
            <Typography align='center' variant='h5' color='#ababab'>Nincs találat erre: "{searchQuery}"</Typography>
        </Stack>}
        <Grid container spacing={2}>
            {partners?.map(u => <Grid key={u?.id} item xs={12} md={6} xl={6}>
                <Partner partner={u}/>
            </Grid>)}
        </Grid>
        {/*<Box sx={{background: '#fff', borderRadius: '8px', padding: '5px'}} marginTop={2}>*/}
        {/*    <Navigation page_number={pageNumber}/>*/}
        {/*</Box>*/}

        <Loading isLoading={loading}/>
    </AuthDrawer>
}

export default PartnerTrafficEffectiveness