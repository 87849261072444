import {Box, Collapse, IconButton, Stack, styled, Typography, useTheme} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useState} from "react";
import DinoDataGrid from "../../components/DataGrid";
import thousandSeparator from "../../utils/NumberUtils";

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const ExpandSector = ({name, data}) => {
    const [expanded, setExpanded] = useState(false)
    const theme = useTheme()

    const handleExpandClick = () => setExpanded(!expanded)

    return <Stack spacing={2} marginTop={4} className="dashboard-card">
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
        }}>
            <Typography color={theme.palette.primary[500]} variant='body1'>{name}</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '15px'
            }}>
                {/*<Stack spacing={1}>*/}
                {/*    <Typography color={theme.palette.primary[500]} variant='caption'>YEARLY AVG Price (/KWH)</Typography>*/}
                {/*    <TextField variant='standard' type='number' disabled value={avgPrice()}/>*/}
                {/*</Stack>*/}
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon sx={{color: theme.palette.primary[500]}}/>
                </ExpandMore>
            </Box>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <DinoDataGrid rows={data} columns={[
                {headerName: 'Partner', field: 'uf_name', width: 300},
                {headerName: 'Forgalom', field: 'traffic', renderCell: p => thousandSeparator(p.row.traffic) + ' Ft', width: 300},
                {headerName: 'Árrés', field: 'margin', renderCell: p => thousandSeparator(p.row.margin) + ' Ft', width: 300},
                // {headerName: 'Darabszám', field: 'count', width: 300},
            ]}/>
        </Collapse>

    </Stack>
}

export default ExpandSector