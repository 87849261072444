import React, { useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardContent,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    List,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const FilterArea = ({ filters, onEditClick, onDeleteClick, onDownloadClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState(null);

    const handleMenuOpen = (event, filter) => {
        setAnchorEl(event.currentTarget);
        setSelectedFilter(filter);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedFilter(null);
    };

    const handleEdit = () => {
        onEditClick(selectedFilter?.id)
        handleMenuClose();
    };

    const handleDelete = () => {
        onDeleteClick(selectedFilter?.id)
        handleMenuClose();
    };

    const handleDownload = () => {
        onDownloadClick(selectedFilter?.id)
        handleMenuClose();
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Szűrések</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    {filters.map((filter) => (
                        <Card
                            key={filter.id}
                            variant="outlined"
                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}
                        >
                            <CardContent sx={{ flexGrow: 1, padding: '8px', '&:last-child': { paddingBottom: '8px' } }}>
                                <Typography variant="body2">{filter.name}</Typography>
                            </CardContent>
                            <IconButton onClick={(event) => handleMenuOpen(event, filter)}>
                                <MoreVertIcon />
                            </IconButton>
                        </Card>
                    ))}
                </List>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <MenuItem onClick={handleEdit}>Szerkesztés</MenuItem>
                    <MenuItem onClick={handleDelete}>Törlés</MenuItem>
                    <MenuItem onClick={handleDownload}>CSV letöltése</MenuItem>
                </Menu>
            </AccordionDetails>
        </Accordion>
    );
};

export default FilterArea;
