import ResponsiveDrawer from "../../components/AuthDrawer";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded';
import {useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    IconButton, ListItemIcon,
    Menu,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import DinoDialog from "../../components/Dialog";
import Loading from "../../components/Loading";
import useCallDataApi from "../../hooks/data";
import DinoDataGrid from "../../components/DataGrid";
import {getFormData} from "../../utils/formUtils";
import thousandSeparator from "../../utils/NumberUtils";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {useHistory} from "react-router-dom";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import {hu} from "date-fns/locale";
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import {getMonths, getYears} from "../../utils/miscUtils";

const ControllingItemNumber = () => {
    const {getData, postData, updateData} = useCallDataApi('general-ledger-analytics')
    const {getData: getGroup} = useCallDataApi('controlling-group')
    const [importDataDialogOpen, setImportDataDialogOpen] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState(parseInt(localStorage.getItem('group-select')) || -1)
    const [selectedMonth, setSelectedMonth] = useState(localStorage.getItem('month-select') || new Date().getMonth() + 1)
    const [selectedYear, setSelectedYear] = useState(localStorage.getItem('year-select') || new Date().getFullYear())
    const [selectedPlace, setSelectedPlace] = useState(localStorage.getItem('place-select') || 'KEF')
    const [groups, setGroups] = useState([])
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const loadDataRef = useRef(null)
    const history = useHistory()

    const loadDataFromMRC = () => {
        const data = getFormData(loadDataRef.current)
        setLoading(true)
        postData('load_from_mrc/', {...data, group: selectedGroup, year: selectedYear, month: selectedMonth, place: selectedPlace}).then(d => window.location.reload()).finally(() => setLoading(false))
    }

    const updateRowColor = (item_number, color) => {
        postData(`color_item_number/`, {
            color: color,
            item_number: item_number,
            group: selectedGroup
        }).then(editedRow => {
            const editedRowList = data.map(row => {
                if (row.id === editedRow.id) return {...row, ...editedRow}
                return row;
            });
            setData(editedRowList)
        })
    }

    const updateItemNumber = (item_number) => {
        setLoading(true)
        postData(`load_item_number_from_mrc/`, {item_number: item_number}).then(() => {
            postData('item_number_view/', {
                group: selectedGroup,
                year: selectedYear,
                month: selectedMonth,
                place: selectedPlace
            }).then(d => setData(d)).finally(() => setLoading(false))
        })
    }

    const colorAllSelected = (color) => {
        setLoading(true)
        postData('color_all_selected/', {
            color: color,
            sector: selectedPlace,
            year: selectedYear,
            month: selectedMonth,
            group: selectedGroup
        }).then(d => setData(d)).finally(() => setLoading(false))
    }

    const handleRowClick = (itemNumber) => {
        history.push(`/item-number/${btoa(itemNumber)}`)
    }

    useEffect(() => {
        getGroup().then(g => setGroups(g))
        document.title = 'Kontrolling'
    }, [])

    useEffect(() => {
        getGroup().then(g => setGroups(g))
        postData('item_number_view/', {
            group: selectedGroup,
            year: selectedYear,
            month: selectedMonth,
            place: selectedPlace
        }).then(d => setData(d)).finally(() => setLoading(false))
        document.title = 'Munkaszámok'
    }, [selectedGroup, selectedYear, selectedMonth, selectedPlace])

    const extraButtons = [
        {
            name: 'Adatok importálása',
            props: {
                variant: 'contained',
                endIcon: <FileDownloadRoundedIcon/>,
                onClick: () => setImportDataDialogOpen(true)
            }
        }
    ]
    const filterButtons = [
        {
            name: 'Tételek',
            props: {
                variant: 'contained',
                onClick: () => history.push('/controlling')
            }
        },
        {
            name: 'Munkaszámok',
            props: {
                variant: 'contained',
                onClick: () => history.push('/item-number')
            }
        }
    ]
    const columns = [
        {
            field: "manage",
            headerName: "Manage",
            sortable: false,
            type: "actions",
            getActions: (params) => [<GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'red')
                }}
                label="Problémás"
                className='super-app-theme--red'
                showInMenu={true}
            />, <GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'green')
                }}
                label="Nem jutalék"
                className='super-app-theme--green'
                showInMenu={true}
            />, <GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'blue')
                }}
                label="Rossz jogcím"
                className='super-app-theme--blue'
                showInMenu={true}
            />, <GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'yellow')
                }}
                label="Rendben"
                className='super-app-theme--yellow'
                showInMenu={true}
            />, <GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'purple')
                }}
                label="Visszamenőleges javítás"
                className='super-app-theme--purple'
                showInMenu={true}
            />, <GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'pink')
                }}
                label="Már nem jutalék alapja"
                className='super-app-theme--pink'
                showInMenu={true}
            />, <GridActionsCellItem
                icon={<ClearRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, null)
                }}
                label="Szín törlése csoportból"
                showInMenu={true}
            />, <GridActionsCellItem
                icon={<ReplayRoundedIcon/>}
                onClick={() => {
                    updateItemNumber(params.row.id)
                }}
                label="Frissítés"
                showInMenu={true}
            />]
        },
        {headerName: 'Munkaszám', field: 'id', width: 200},
        {
            headerName: 'Tételek száma',
            field: 'number_of_items',
            type: 'number',
        },
        {
            headerName: 'Színek',
            field: 'color_count',
            width: 200,
            valueGetter: p => JSON.stringify(p.row.color_count),
            renderCell: params => {
                return <div style={{display: 'flex', gap: 10}}>
                    {Object.keys(params.row.color_count).map(key => <div key={params.row.item_number + key} style={{
                        display: params.row.color_count[key] > 0 ? 'flex' : 'none',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 30,
                        height: 30,
                        borderRadius: '30px',
                        fontWeight: 'bold',
                        background: key
                    }}>{params.row.color_count[key]}</div>)}
                    {params.row.changed_count > 0 && <div style={{color: 'red', fontWeight: 'bold', textAlign: 'center'}}>!{params.row.changed_count}</div>}
                </div>
            }
        },
        {
            headerName: 'Bevétel',
            field: 'summed_income',
            type: 'number',
        },
        {
            headerName: 'Ráfordítás',
            field: 'summed_expense',
            type: 'number',
        },
        {
            headerName: 'Egyenleg',
            field: 'summed_gross_value',
            type: 'number',
            valueGetter: params => parseFloat(params.row.summed_income) - parseFloat(params.row.summed_expense),
            renderCell: params => {
                const val = parseFloat(params.row.summed_income) - parseFloat(params.row.summed_expense)
                return <p style={{color: val > 0 ? 'green' : 'red'}}>{thousandSeparator(val)}</p>
            }
        },
        {
            headerName: 'Kommentek',
            field: 'comments',
        },
    ]

    function getSumIncome() {
        return data.reduce((sum, obj) => sum + obj.summed_income, 0);
    }

    function getSumExpense() {
        return data.reduce((sum, obj) => sum + obj.summed_expense, 0);
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <ResponsiveDrawer label='Alaptábla' filterButtons={filterButtons} buttons={extraButtons}>
        <Box sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 2
        }}>
            <Box justifyContent='center' alignItems='center'>
                <IconButton onClick={handleClick}>
                    <MoreVertRoundedIcon/>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => {
                        colorAllSelected('red')
                        handleClose()
                    }}
                              className='super-app-theme--red'>
                        <ListItemIcon>
                            <ColorLensRoundedIcon/>
                        </ListItemIcon>
                        Összes rossz problémás
                    </MenuItem>
                    <MenuItem onClick={() => {
                        colorAllSelected('green')
                        handleClose()
                    }}
                              className='super-app-theme--green'>
                        <ListItemIcon>
                            <ColorLensRoundedIcon/>
                        </ListItemIcon>
                        Összes nem jutalék
                    </MenuItem>
                    <MenuItem onClick={() => {
                        colorAllSelected('blue')
                        handleClose()
                    }}
                              className='super-app-theme--blue'>
                        <ListItemIcon>
                            <ColorLensRoundedIcon/>
                        </ListItemIcon>
                        Összes rossz jogcím
                    </MenuItem>
                    <MenuItem onClick={() => {
                        colorAllSelected('yellow')
                        handleClose()
                    }}
                              className='super-app-theme--yellow'>
                        <ListItemIcon>
                            <ColorLensRoundedIcon/>
                        </ListItemIcon>
                        Összes rendben
                    </MenuItem>
                    <MenuItem onClick={() => {
                        colorAllSelected('purple')
                        handleClose()
                    }}
                              className='super-app-theme--purple'>
                        <ListItemIcon>
                            <ColorLensRoundedIcon/>
                        </ListItemIcon>
                        Összes visszamenőleges javítás
                    </MenuItem>
                    <MenuItem onClick={() => {
                        colorAllSelected('pink')
                        handleClose()
                    }}
                              className='super-app-theme--pink'>
                        <ListItemIcon>
                            <ColorLensRoundedIcon/>
                        </ListItemIcon>
                        Összes már nem jutalék alapja
                    </MenuItem>
                </Menu>
            </Box>

            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                justifyContent: 'flex-end',
                marginBottom: 2,
                gap: 2,
            }}>
                <FormControl>
                    <Select
                        id="place-select"
                        value={selectedPlace}
                        onChange={e => {
                            const selected = e.target.value
                            localStorage.setItem('place-select', selected)
                            setSelectedPlace(selected)
                        }}
                    >
                        <MenuItem value='KEF'>KEF</MenuItem>
                        <MenuItem value='Vállalati általános'>Vállalati általános</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <Select
                        id="year-select"
                        value={selectedYear}
                        onChange={e => {
                            const selected = e.target.value
                            localStorage.setItem('year-select', selected)
                            setSelectedYear(selected)
                        }}
                    >
                        <MenuItem value={2023}>2023</MenuItem>
                        <MenuItem value={2024}>2024</MenuItem>
                        <MenuItem value={2025}>2025</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <Select
                        id="month-select"
                        value={selectedMonth}
                        onChange={e => {
                            const selected = e.target.value
                            localStorage.setItem('month-select', selected)
                            setSelectedMonth(selected)
                        }}
                    >
                        <MenuItem value={1}>Jan</MenuItem>
                        <MenuItem value={2}>Feb</MenuItem>
                        <MenuItem value={3}>Mar</MenuItem>
                        <MenuItem value={4}>Apr</MenuItem>
                        <MenuItem value={5}>Maj</MenuItem>
                        <MenuItem value={6}>Jun</MenuItem>
                        <MenuItem value={7}>Jul</MenuItem>
                        <MenuItem value={8}>Aug</MenuItem>
                        <MenuItem value={9}>Szep</MenuItem>
                        <MenuItem value={10}>Okt</MenuItem>
                        <MenuItem value={11}>Nov</MenuItem>
                        <MenuItem value={12}>Dec</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <Select
                        id="group-select"
                        value={selectedGroup}
                        onChange={e => {
                            const selected = e.target.value
                            setSelectedGroup(selected)
                            localStorage.setItem('group-select', selected)
                        }}
                    >
                        <MenuItem value={-1}>Összes csoport</MenuItem>
                        {groups.map(g => <MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>

        </Box>
        <DinoDataGrid rows={data} columns={columns} onRowClick={params => handleRowClick(params.id)}/>
        <Stack direction='row' justifyContent='space-between' marginTop={2}>
            <Typography>Össz bevétel: {thousandSeparator(getSumIncome())} Ft</Typography>
            <Typography>Össz kiadás: {thousandSeparator(getSumExpense())} Ft</Typography>
            <Typography>Össz egyenleg: {thousandSeparator(getSumIncome() - getSumExpense())} Ft</Typography>
        </Stack>

        <DinoDialog open={importDataDialogOpen} handleClose={() => setImportDataDialogOpen(false)}
                    title='Adatok importálása' subtitle='Válaszd ki mikori adatokat szeretnél importálni'
                    actions={<Stack direction='row' spacing={3}>
                        <Button variant='outlined' onClick={() => setImportDataDialogOpen(false)}>Mégsem</Button>
                        <Button variant='contained' endIcon={<FileDownloadRoundedIcon/>}
                                onClick={() => {
                                    setImportDataDialogOpen(false)
                                    loadDataFromMRC()
                                }}>Adatok importálása</Button>
                    </Stack>}>
            <Stack spacing={1}>
                <Typography variant='subtitle2' color='#062B45'>Idősáv</Typography>
                <Typography variant='body1' color='#38556A'>Válaszd ki mikori adatokat szeretnél importálni</Typography>
                <form ref={loadDataRef}>
                    <Stack spacing={2} direction='row'>
                        <FormControl>
                            <Select
                                id="year-select-load"
                                name='load-year'
                                variant='standard'
                                defaultValue={new Date().getFullYear()}
                            >
                                {getYears().map(y => <MenuItem value={y} key={y}>{y}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <Select
                                id="month-select-load"
                                name='load-month'
                                defaultValue={new Date().getMonth() + 1}
                                variant='standard'
                            >
                                {getMonths().map(m => <MenuItem key={m?.number + m?.name} value={m?.number}>{m?.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Stack>
                </form>
            </Stack>
        </DinoDialog>


        <Loading isLoading={loading}/>
    </ResponsiveDrawer>
}

export default ControllingItemNumber