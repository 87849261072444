import {useCallback, useContext} from "react";
import {useAuthHeader} from "./hooks.js";
import ConfigContext from "../context/ConfigContext";
import axios from 'axios';
import AuthContext from "../context/AuthContext";
import {useHistory} from "react-router-dom";
import {ensureTrailingSlash} from "../utils/miscUtils";

function useCallDataApi(model) {
    const history = useHistory()
    const {headers} = useAuthHeader();
    const {logoutUser, updateToken} = useContext(AuthContext)
    const {baseUrl} = useContext(ConfigContext);

    const url = baseUrl + '/' + model + '/';

    const authenticatedAPI = axios.create({
        baseURL: url,
        headers: headers
    })

    const handleError = (e) => {
        if (e?.code === 'ERR_NETWORK') alert('A szolgáltatás jelenleg nem elérhető, ellenőrízd az internethozzáfárásedet.')
        if (e.response.status === 401) {
            logoutUser()
            return
        }
        alert(e.response?.data?.data)
        window.location.reload()
    }

    const createData = useCallback(async (data) => {
            try {
                const promise = await authenticatedAPI.post("", JSON.stringify(data))
                return promise?.data;
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    const newToken = await updateToken();
                    error.config.headers['Authorization'] = `Bearer ${newToken}`
                    const newResponse = await axios(error.config)
                    return newResponse?.data
                }
                handleError(error);
            }
        },
        [authenticatedAPI]
    );

    const postData = useCallback(async (slug, data) => {
            try {
                const promise = await authenticatedAPI.post(ensureTrailingSlash(slug), JSON.stringify(data))
                return promise?.data;
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    const newToken = await updateToken();
                    error.config.headers['Authorization'] = `Bearer ${newToken}`
                    const newResponse = await axios(error.config)
                    return newResponse?.data
                }
                handleError(error);
            }
        },
        [authenticatedAPI]
    );

    const getData = useCallback(async (id = null, filters = null) => {
        try {
            let fetchUrl = id
            if (filters) {
                const queryParams = new URLSearchParams(filters).toString();
                fetchUrl = `${url}?${queryParams}`;
            }
            const response = await authenticatedAPI.get(fetchUrl);
            return response?.data;
        } catch (error) {
            if (error.response && [401, 403].includes(error.response.status)) {
                const newToken = await updateToken();
                error.config.headers['Authorization'] = `Bearer ${newToken}`;
                const newResponse = await axios(error.config);
                return newResponse?.data;
            }
            handleError(error); // Handle other errors
        }
    }, [authenticatedAPI]);


    // const getData = useCallback(async (id = null, filters= null) => {
    //         try {
    //             const promise = await authenticatedAPI.get(ensureTrailingSlash(id));
    //             return promise?.data;
    //         } catch (error) {
    //             if (error.response && [401, 403].includes(error.response.status)) {
    //                 const newToken = await updateToken();
    //                 error.config.headers['Authorization'] = `Bearer ${newToken}`
    //                 const newResponse = await axios(error.config)
    //                 return newResponse?.data
    //             }
    //             handleError(error);
    //         }
    //     },
    //     [authenticatedAPI]
    // );

    const getBlob = useCallback(async (id = null) => {
            try {
                const promise = await authenticatedAPI.get(ensureTrailingSlash(id), { responseType: 'blob' })
                return promise?.data;
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    const newToken = await updateToken();
                    error.config.headers['Authorization'] = `Bearer ${newToken}`
                    const newResponse = await axios(error.config)
                    return newResponse?.data
                }
                handleError(error);
            }
        },
        [authenticatedAPI]
    );

    const updateData = useCallback(async (id, data) => {
            try {
                const promise = await authenticatedAPI.patch(ensureTrailingSlash(id), JSON.stringify(data))
                return promise?.data;
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    const newToken = await updateToken();
                    error.config.headers['Authorization'] = `Bearer ${newToken}`
                    const newResponse = await axios(error.config)
                    return newResponse?.data
                }
                handleError(error);
            }

        },
        [authenticatedAPI]
    );

    const deleteData = useCallback(async (id) => {
            try {
                const promise = await authenticatedAPI.delete(ensureTrailingSlash(id))
                return promise?.data;
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    const newToken = await updateToken();
                    error.config.headers['Authorization'] = `Bearer ${newToken}`
                    const newResponse = await axios(error.config)
                    return newResponse?.data
                }
                handleError(error);
            }
        },
        [authenticatedAPI]
    );

    const getFile = useCallback(
        async (id, extension, name='data') => {
            const response = await fetch(ensureTrailingSlash(url + id), {
                method: 'GET',
                headers,
            });
            const blob = await response?.blob();
            if (response.status === 401) {
                logoutUser();
            }
            if (response.status === 403) {
                history.push("/");
            }
            if (response.status === 200) {
                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = `${name}.${extension}`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
            return response;
        },
        [headers, logoutUser]
    );

    return {
        getData,
        createData,
        updateData,
        deleteData,
        postData,
        getFile
    };
}

export default useCallDataApi;