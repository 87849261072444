import {createContext, useState, useCallback, useContext} from "react";
import jwt_decode from "jwt-decode";
import {useHistory} from "react-router-dom";
import ConfigContext from "./ConfigContext";
import axios from "axios";
import {useSnackbar} from "notistack";


const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {
    const {baseUrl} = useContext(ConfigContext)
    let at = localStorage.getItem("authTokens");
    const perm = JSON.parse(localStorage.getItem("permissions"));

    const [authTokens, setAuthTokens] = useState(() => at ? JSON.parse(at) : null);
    const [user, setUser] = useState(() => at ? jwt_decode(at) : null);
    const [permissions, setPermissions] = useState(perm || [])
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar()

    const api = axios.create({
        baseURL: baseUrl,
        headers: {"Content-Type": "application/json"}
    })

    const setAuthStorage = (data) => {
        setAuthTokens(data);
        const u = jwt_decode(data.access);
        setUser(u);
        setPermissions(u.permissions);
        localStorage.setItem("authTokens", JSON.stringify(data));
        localStorage.setItem("permissions", JSON.stringify(u?.permissions));
    }

    const loginUser = (data) => {
        api.post('api/token/', JSON.stringify(data))
            .then(r => {
                setAuthStorage(r?.data)
                history.push("/")
            })
            .catch(e => {
                const status = e?.response?.status
                if (status === 401) enqueueSnackbar('Hiba, helytelen email vagy jelszó!', {variant: 'error'})
                else enqueueSnackbar('Hiba a bejelentkezésnél, próbálja újra később!', {variant: 'error'})
                console.log(e)
            })
    }

    const logoutUser = useCallback(() => {
        setAuthTokens(null);
        setUser(null);
        setPermissions(null);
        localStorage.removeItem("authTokens");
        localStorage.removeItem("permissions");
    }, []);

    const updateToken = useCallback(() => {
        return api.post('api/token/refresh/', JSON.stringify({refresh: authTokens?.refresh}))
            .then(r => {
                const data = r?.data
                setAuthStorage(data)
                return data.access
            })
            .catch(e => {
                logoutUser()
                throw e
            })
    }, [authTokens?.refresh, logoutUser])

    const contextData = {
        user: user,
        authTokens,
        loginUser,
        logoutUser,
        permissions,
        updateToken
    };

    return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};