import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Stack,
    useTheme
} from "@mui/material";
import whiteLogo from "../assets/logos/jet-travel-logo-white 1.png"
import {Link, useHistory} from "react-router-dom";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import {useContext, useState} from "react";
import AuthContext from "../context/AuthContext";
import DinoDialog from "./Dialog";
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import BubbleChartRoundedIcon from '@mui/icons-material/BubbleChartRounded';
import packageJson from '../../package.json'
import menuItems from "../menuItems";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
    const {w} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false)
    const theme = useTheme()
    const {logoutUser, permissions} = useContext(AuthContext)
    const history = useHistory()

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div style={{background: '#fff', height: '100%'}}>

            <Toolbar style={{background: '#fff', alignItems: 'center', justifyContent: 'center'}}>
                <img src={whiteLogo} width='110px' style={{margin: '10px', cursor: 'pointer'}} onClick={() => history.push('/')}/>
            </Toolbar>
            {/*<Divider/>*/}
            <List sx={{padding: '32px 16px'}}>
                {menuItems?.filter(item => item?.permissions?.every(p => permissions?.includes(p))).map((item, index) => (
                    <ListItem key={item.name} className="menu-item" disablePadding sx={{paddingBottom: '3px'}}
                              component={Link}
                              to={item.url}>
                        <ListItemButton className="menu-button" sx={{background: props.label === item.name && theme.palette.primary[600], "&:hover": {background: theme.palette.primary[400]}}}>
                            <ListItemIcon className="menu-button__icon">
                                {item.icon}
                            </ListItemIcon>
                            <Typography variant="button" className="menu-button__text">{item.name}</Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Typography variant='caption' sx={{
                color: '#062B45',
                position: 'absolute',
                bottom: 40,
                left: '50%',
                transform: 'translateX(-50%)',
                '&:hover': {background: 'transparent'}
            }}>v. {packageJson.version}</Typography>
            <Button variant='text' sx={{
                color: '#062B45',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                '&:hover': {background: 'transparent'}
            }} startIcon={<LogoutRoundedIcon/>} onClick={() => setLogoutDialogOpen(true)}>kijelentkezés</Button>
        </div>
    );

    const container = w !== undefined ? () => w().document.body : undefined;

    const logoutActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setLogoutDialogOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setLogoutDialogOpen(false)
            logoutUser()
        }} endIcon={<LogoutRoundedIcon/>}>Kijelentkezés</Button>
    </>

    return (
        <Box sx={{display: 'flex', height: '100%'}}>
            <CssBaseline/>
            <AppBar sx={{
                width: {md: `calc(100% - ${drawerWidth}px)`},
                ml: {md: `${drawerWidth}px`},
                background: '#fff',
                color: `${theme.palette.primary[500]} !important`,
                boxShadow: 'none'
            }} position="fixed">
                <Container maxWidth="xl">

                    <Toolbar disableGutters>
                        <Stack spacing={1} >
                            <Typography variant="headline2" noWrap component="div" color='#062B45'>
                                {props.label}
                            </Typography>
                            <Stack direction='row' spacing={2} sx={{ display: {xs: 'none', sm: 'none', md: 'flex'}, padding: {xs: 'none', sm: 'none', md: '5px'}}}>
                                {props.filterButtons?.map((button, i) =>
                                    <Button key={`f_button_${i}`} {...button.props}>{button.name}</Button>)}
                            </Stack>
                        </Stack>
                        {/*<Box sx={{flexGrow: 0, display: {xs: 'flex', sm: 'flex', md: 'none'}}}>*/}
                        {/*    <img src={whiteLogo} alt="Logo" width='100px'/>*/}
                        {/*</Box>*/}

                        <Box sx={{display: {xs: 'flex', md: 'none'}, marginLeft: 'auto', zIndex: '11'}}>
                            <div >
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleDrawerToggle}
                                    color="inherit"
                                >
                                    {!mobileOpen ? <MenuIcon/> : <CloseIcon/>}
                                </IconButton>
                            </div>
                        </Box>

                        <Stack sx={{
                            flexGrow: 1,
                            display: {xs: 'none', sm: 'none', md: 'flex'},
                            justifyContent: 'flex-end'
                        }} direction='row' spacing={1}>
                            {props.buttons?.map((button, i) =>
                                <Button
                                    key={`menu_button_${i}`}
                                    {...button.props}>{button.name}</Button>)}
                        </Stack>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {md: drawerWidth}, flexShrink: {sm: 0}, '& .MuiDrawer-paper': {border: 'none', boxShadow: '0 4px 20px #20292E08'}}}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {sm: 'block', md: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', sm: 'none', md: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{flexGrow: 1, height: '100%', width: {sm: '100%', md: `calc(100% - ${drawerWidth}px)`}}}
            >
                <Toolbar/>
                <div className="main">
                    <Box sx={{
                        width: '100%',
                        display: {xs: 'flex', sm: 'flex', md: 'none'},
                        flexDirection: {xs: 'column', md: 'row'},
                        justifyContent: 'flex-end',
                        marginBottom: 2,
                        gap: 2,
                    }}>
                        {props.buttons?.map((button, i) => <Button key={`2menu_button_${i}`} {...button.props}>{button.name}</Button>)}
                        {props.filterButtons?.map((button, i) => <Button key={`2f_button_${i}`} {...button.props}>{button.name}</Button>)}
                    </Box>

                    {props.children}
                </div>
                {/*{props?.filterButtonsMobile ? <Box sx={{height: 110, display: {xs: 'block', md: 'none'}}}/> : <Box sx={{height: 70, display: {xs: 'block', md: 'none'}}}/>}*/}


                <DinoDialog open={logoutDialogOpen}
                            handleClose={() => setLogoutDialogOpen(false)}
                            title='Kijelentkezés'
                            subtitle='Biztosan kijelentkezik?'
                            actions={logoutActions}>

                </DinoDialog>
            </Box>
        </Box>
    );
}

export default ResponsiveDrawer;