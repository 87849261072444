import ResponsiveDrawer from "../../components/AuthDrawer";
import {useHistory, useParams} from "react-router-dom";
import {
    Box,
    Button,
    Divider,
    FormControl,
    IconButton, ListItemIcon, Menu,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import useCallDataApi from "../../hooks/data";
import {useEffect, useRef, useState} from "react";
import Loading from "../../components/Loading";
import DinoDataGrid from "../../components/DataGrid";
import {GridActionsCellItem} from "@mui/x-data-grid";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import thousandSeparator from "../../utils/NumberUtils";
import DinoDialog from "../../components/Dialog";
import {getFormData} from "../../utils/formUtils";
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import {PaletteRounded} from "@mui/icons-material";


const ControllingDetail = () => {
    const params = useParams()
    const history = useHistory()
    const {postData} = useCallDataApi('general-ledger-analytics')
    const itemNumber = atob(params.slug)
    const [data, setData] = useState([])
    const [current, setCurrent] = useState(-1)
    const [loading, setLoading] = useState(true)
    const [commentDialogOpen, setCommentDialogOpen] = useState(false)
    const {getData: getGroup} = useCallDataApi('controlling-group')
    const [selectedGroup, setSelectedGroup] = useState(parseInt(localStorage.getItem('group-select')) || -1)
    const [groups, setGroups] = useState([])
    const commentForm = useRef(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null);

    useEffect(() => {
        if (selectedGroup === -1) postData('get_for_item_number/', {item_number: itemNumber})
            .then(d => setData(d))
            .finally(() => setLoading(false))
        getGroup()
            .then(g => setGroups(g))
    }, [])

    useEffect(() => {
        setLoading(true)
        postData('get_for_item_number_with_filter/', {
            group: selectedGroup,
            item_number: itemNumber
        }).then(d => setData(d)).finally(() => setLoading(false))
    }, [selectedGroup])

    const newOrEditComment = () => {
        const newComment = getFormData(commentForm.current)
        postData('set_comment/', {id: current, comment: newComment?.comment}).then(editedRow => {
            const editedDataList = data.map(row => {
                if (row.id === current) return {...row, ...editedRow}
                return row;
            });
            setData(editedDataList)
        })
    }

    const updateRowColor = (id, color) => {
        postData(`color_item/`, {color: color, id: id}).then(editedRow => {
            const editedDataList = data.map(row => {
                if (row.id === id) return {...row, ...editedRow}
                return row;
            });
            setData(editedDataList)
        })
    }

    const updateRowsColor = (id_list, color) => {
        setLoading(true)
        postData(`color_items/`, {color: color, id: id_list, group: selectedGroup})
            .then(editedRows => setData(editedRows))
            .finally(() => setLoading(false))
    }

    const income = () => {
        let i = 0
        for (let d of data) {
            if (![5, 8].includes(d.mirr_class)) i += d.gross_value
        }
        return Math.round(i * 10) / 10
    }

    const expense = () => {
        let e = 0
        for (let d of data) {
            if ([5, 8].includes(d.mirr_class)) e += d.gross_value
        }
        return Math.round(e * 10) / 10
    }

    const balance = () => Math.round((income() - expense()) * 10) / 10

    const columns = [
        {
            field: "manage",
            headerName: "Manage",
            sortable: false,
            type: "actions",
            getActions: (params) => [<GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'yellow')
                }}
                label="Rendben"
                className='super-app-theme--yellow'
                showInMenu={true}
            />, <GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'red')
                }}
                label="Problémás"
                className='super-app-theme--red'
                showInMenu={true}
            />, <GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'green')
                }}
                label="Nem jutalék"
                className='super-app-theme--green'
                showInMenu={true}
            />, <GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'blue')
                }}
                label="Rossz jogcím"
                className='super-app-theme--blue'
                showInMenu={true}
            />, <GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'purple')
                }}
                label="Visszamenőleges javítás"
                className='super-app-theme--purple'
                showInMenu={true}
            />, <GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'pink')
                }}
                label="Már nem jutalék alapja"
                className='super-app-theme--pink'
                showInMenu={true}
            />,
                <GridActionsCellItem
                    icon={<ClearRoundedIcon/>}
                    onClick={() => {
                        updateRowColor(params.row.id, null)
                    }}
                    label="Szín törlése"
                    showInMenu={true}
                />, <GridActionsCellItem
                    icon={<EditRoundedIcon/>}
                    onClick={() => {
                        setCommentDialogOpen(true)
                        setCurrent(params.row.id)
                    }}
                    label="Komment"
                    showInMenu={true}
                />]
        },
        {headerName: 'Munkaszám', field: 'MUNKASZAM', width: 200},
        {headerName: 'Tétel megnevezés', field: 'mirr_name1', width: 300},
        {headerName: 'Főkönyvi szám', field: 'mirr_code'},
        {headerName: 'Ügyfélnév', field: 'UGYFEL_NEV', width: 200},
        {headerName: 'Bizonylat azonosító', field: 'BIZ_AZONOS'},
        {headerName: 'Bizonylat iktatószám', field: 'BIZ_IKTATO'},
        {headerName: 'Költséghely név', field: 'KTGHELY'},
        {headerName: 'Tétel megjegyzés', field: 'MEGJEGYZES'},
        {headerName: 'Deviza', field: 'DEVIZA'},
        {headerName: 'Teljesítés dátuma', field: 'TELJDAT', type: 'date'},
        {headerName: 'Kiállítás dátuma', field: 'KIALLDAT', type: 'date'},
        {headerName: 'Áfa kód', field: 'AFAKOD'},
        {headerName: 'Árfolyam', field: 'ARFOLYAM', type: 'number'},
        {headerName: 'T/K', field: 'mirr_type'},
        {headerName: 'Komment', field: 'comment'},
        {headerName: 'Devizás összeg', field: 'DEV_OSSZ', type: 'number'},
        {headerName: 'Tartozik összeg', field: 'T_OSSZ', type: 'number'},
        {headerName: 'Követel összeg', field: 'K_OSSZ', type: 'number'},
        {
            headerName: 'Nettó összeg',
            field: 'gross_value',
            type: 'number',
            renderCell: params => {
                if (params.row['gross_value'] > 0) return <p
                    style={{color: 'green'}}>{thousandSeparator(params.row['gross_value'])}</p>
                else return <p style={{color: 'red'}}>{thousandSeparator(params.row['gross_value'])}</p>
            }
        },
    ]

    return <ResponsiveDrawer label={itemNumber}>
        <Stack spacing={20} direction='row' marginBottom={4}>
            <Button variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}
                    onClick={() => history.goBack()}>Vissza</Button>
            <Stack spacing={1}>
                <Typography variant='overline' color='#646464'>Bevétel</Typography>
                <Typography variant='subtitle2' color='#09854A'>{thousandSeparator(income())} Ft</Typography>
            </Stack>
            <Stack spacing={1}>
                <Typography variant='overline' color='#646464'>Ráfordítás</Typography>
                <Typography variant='subtitle2' color='#85092F'>{thousandSeparator(expense())} Ft</Typography>
            </Stack>
            <Stack spacing={1}>
                <Typography variant='overline' color='#646464'>Egyenleg</Typography>
                <Typography variant='subtitle2'
                            color={balance() > 0 ? '#09854A' : '#85092F'}>{thousandSeparator(balance())} Ft</Typography>
            </Stack>
        </Stack>
        <Divider/>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Stack spacing={2} alignItems='center' justifyContent='center'>
                <IconButton
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <MoreVertRoundedIcon/>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => {
                        handleClose()
                        updateRowsColor(data.map(d => d.id), 'yellow')
                    }} className='super-app-theme--yellow'>
                        <ListItemIcon>
                            <PaletteRounded fontSize="small"/>
                        </ListItemIcon>
                        Rendben
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleClose()
                        updateRowsColor(data.map(d => d.id), 'red')
                    }} className='super-app-theme--red'>
                        <ListItemIcon>
                            <PaletteRounded fontSize="small"/>
                        </ListItemIcon>
                        Problémás
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleClose()
                        updateRowsColor(data.map(d => d.id), 'blue')
                    }} className='super-app-theme--blue'>
                        <ListItemIcon>
                            <PaletteRounded fontSize="small"/>
                        </ListItemIcon>
                        Rossz jogcím
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleClose()
                        updateRowsColor(data.map(d => d.id), 'green')
                    }} className='super-app-theme--green'>
                        <ListItemIcon>
                            <PaletteRounded fontSize="small"/>
                        </ListItemIcon>
                        Nem jutalék
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleClose()
                        updateRowsColor(data.map(d => d.id), 'purple')
                    }} className='super-app-theme--purple'>
                        <ListItemIcon>
                            <PaletteRounded fontSize="small"/>
                        </ListItemIcon>
                        Visszamenőleges javítás
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleClose()
                        updateRowsColor(data.map(d => d.id), 'pink')
                    }} className='super-app-theme--pink'>
                        <ListItemIcon>
                            <PaletteRounded fontSize="small"/>
                        </ListItemIcon>
                        Már nem jutalék alapja
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleClose()
                        updateRowsColor(data.map(d => d.id), null)
                    }}>
                        <ListItemIcon>
                            <PaletteRounded fontSize="small"/>
                        </ListItemIcon>
                        Színek törlése
                    </MenuItem>
                </Menu>
            </Stack>
            <FormControl sx={{alignSelf: 'center', marginBottom: 2, marginTop: 2}}>
                <Select
                    id="group-select"
                    value={selectedGroup}
                    onChange={e => {
                        const selected = e.target.value
                        setSelectedGroup(selected)
                        localStorage.setItem('group-select', selected)
                    }}
                >
                    <MenuItem value={-1}>Összes csoport</MenuItem>
                    {groups.map(g => <MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>

        <DinoDataGrid rows={data} columns={columns}/>
        <Loading isLoading={loading}/>

        <DinoDialog open={commentDialogOpen}
                    handleClose={() => setCommentDialogOpen(false)}
                    title='Komment módosítása'
                    subtitle='Ird ide a megjegyzésedet a tételhez'
                    actions={<Stack direction='row' spacing={2}>
                        <Button variant='outlined' onClick={() => setCommentDialogOpen(false)}>Mégsem</Button>
                        <Button variant='contained' startIcon={<EditRoundedIcon/>} onClick={() => {
                            newOrEditComment()
                            setCommentDialogOpen(false)
                        }}>Komment módosítása</Button>
                    </Stack>}>
            <form ref={commentForm}>
                <Stack spacing={2}>
                    <Typography variant='overline'>Komment</Typography>
                    <TextField name='nullable-comment' variant='standard'
                               defaultValue={data.find(d => d.id === current)?.comment}/>
                </Stack>
            </form>
        </DinoDialog>
    </ResponsiveDrawer>
}

export default ControllingDetail