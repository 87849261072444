import AuthDrawer from "../../components/AuthDrawer";
import menuItems from "../../menuItems";
import ListItem from "@mui/material/ListItem";
import {Link} from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useContext} from "react";
import AuthContext from "../../context/AuthContext";
import {Box, Grid, useTheme} from "@mui/material";
import jetLogo from "../../assets/logos/jet-travel-logo-white.svg"

const Home = () => {
    document.title = 'Üdvözöljük!'
    const {permissions} = useContext(AuthContext)

    const theme = useTheme()
    return <AuthDrawer label='Üdvözöljük!'>
        <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#ddd', borderRadius: '8px'}}>
            <img src={jetLogo} alt="jet travel logo" style={{width: '50%'}}/>
        </Box>

        {/*<Grid container spacing={2}>*/}
        {/*    {menuItems.filter(item => item.permissions.every(i => permissions?.includes(i))).map(item => <Grid*/}
        {/*        key={item.name} item xs={12} lg={2}>*/}
        {/*        <Link to={item?.url} style={{textDecoration: 'none'}}>*/}
        {/*            <Box sx={{*/}
        {/*                height: '150px',*/}
        {/*                background: theme.palette.primary.main,*/}
        {/*                color: theme.palette.background.default,*/}
        {/*                borderRadius: '5px',*/}
        {/*                padding: '10px',*/}
        {/*                display: 'flex',*/}
        {/*                flexDirection: 'column',*/}
        {/*                justifyContent: 'center',*/}
        {/*                alignItems: 'center',*/}
        {/*                gap: '10px'*/}
        {/*            }}>*/}
        {/*                {item?.icon}*/}
        {/*                <Typography variant='h5'>{item?.name}</Typography>*/}
        {/*            </Box>*/}
        {/*        </Link>*/}
        {/*    </Grid>)}*/}
        {/*</Grid>*/}

    </AuthDrawer>
}

export default Home