import {createTheme} from "@mui/material/styles";
import {huHU} from "@mui/material/locale";
import typography from "./typography";
import {components} from "./componentDef";


const grey = {
    '900': '#0D0D0D',
    '800': '#222222',
    '700': '#434343',
    'A700': '#434343',
    '600': '#646464',
    '500': '#848484',
    '400': '#9D9D9D',
    'A400': '#9D9D9D',
    '300': '#B5B5B5',
    '200': '#CECECE',
    'A200': '#CECECE',
    '100': '#E7E7E7',
    'A100': '#E7E7E7',
    '50': '#F3F3F3',
    '20': '#F5F5F5'
}

const lightBlue = {
    '900': '#0D0F13',
    '800': '#1F262F',
    '700': '#3F4D5D',
    'A700': '#3F4D5D',
    '600': '#5E738C',
    '500': '#7D99BA',
    '400': '#97ADC8',
    'A400': '#97ADC8',
    '300': '#B1C2D6',
    '200': '#CBD6E3',
    'A200': '#CBD6E3',
    '100': '#E5EBF1',
    'A100': '#E5EBF1',
    '50': '#EBEEF5',
    '20': '#F8F9FC'
}

const darkBlue = {
    '900': '#010407',
    '800': '#020B11',
    '700': '#031623',
    'A700': '#031623',
    '600': '#052034',
    '500': '#062B45',
    '400': '#062B45',
    'A400': '#062B45',
    '300': '#38556A',
    '200': '#6A808F',
    'A200': '#6A808F',
    '100': '#9BAAB5',
    'A100': '#9BAAB5',
    '50': '#CDD5DA',
    '20': '#E7EAED'
}


const jetTheme = createTheme({
        components: components,
        name: 'jet',
        palette: {
            primary: lightBlue,
            secondary: grey,
            special: darkBlue
        },
        typography: typography
    },
    huHU
)

export default jetTheme