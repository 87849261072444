import React from "react";
import { Chart } from "react-google-charts";



const GoogleLineChart = ({options, data}) => {
    return (
        <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
    );
}

export default GoogleLineChart
