import {Backdrop, CircularProgress, useTheme} from "@mui/material";


const Loading = ({isLoading}) => {
    const t = useTheme()
    return <Backdrop
        sx={{zIndex: (theme) => theme.zIndex.drawer + 1, backdropFilter: "blur(5px)"}}
        open={isLoading}
    >
        <CircularProgress style={{color: t.palette.primary[700]}} />
    </Backdrop>
}

export default Loading