import {Stack, Typography} from "@mui/material";
import CustomTextField from "../../components/formValidation/CustomTextField";
import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingForm";
import {useState} from "react";
import {validateRequired} from "../../components/formValidation/validators";

const ReportData = ({reference, obj}) => {
    const [data, setData] = useState({
        name: '' || obj?.name
    })
    const [errors, setErrors] = useState({
        name: false
    })
    const validators = {
        name: [validateRequired]
    }
    return <ErrorHandlingForm reference={reference} data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators}>
        <Stack spacing={2}>
            <Stack spacing={1}>
                <Typography variant='caption'>Név</Typography>
                <CustomTextField
                    name='name'
                    reference={reference}
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                />
            </Stack>
        </Stack>
    </ErrorHandlingForm>
}

export default ReportData