import {
    Avatar,
    Box,
    Card,
    CardActions,
    CardContent,
    Grid,
    IconButton,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, {useEffect, useRef, useState} from "react";
import DinoDialog from "../../components/Dialog";
import useCallDataApi from "../../hooks/data";
import Button from "@mui/material/Button";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import Loading from "../../components/Loading";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ResponsiveDrawer from "../../components/AuthDrawer";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import ReportData from "./ReportData";

const Reports = () => {
    const {
        getData: fetchReports,
        postData: newReport,
        updateData: updateReport,
        deleteData: deleteReport
    } = useCallDataApi('report-system/report')

    const [loading, setLoading] = useState(true)
    const [current, setCurrent] = useState(1)
    const [isNewReportDialogOpen, setNewReportDialogOpen] = useState(false)
    const [isEditReportDialogOpen, setEditReportDialogOpen] = useState(false)
    const [isDeleteReportDialogOpen, setDeleteReportDialogOpen] = useState(false)
    const [reports, setReports] = useState([])
    const {enqueueSnackbar} = useSnackbar()
    const history = useHistory()
    const theme = useTheme()

    const reportForm = useRef(null)

    const findReport = (id) => {
        return reports.find(r => r.id === id)
    }

    useEffect(() => {
        document.title = 'Riportok'
        const getData = async () => {
            const [r] = await Promise.all([
                fetchReports(),
            ])
            if (r) {
                setReports(r)
                console.log(r)
            }
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createReport = data => {
        newReport('', data).then(n => {
            setReports([...reports, n])
            history.push(`/report/${n?.id}`)
            enqueueSnackbar('Riport elkészítve!', {variant: 'success'})
        })
    }

    const editReport = data => {
        updateReport(current, data).then(editedReport => {
            const editedReportList = reports.map(report => {
                if (current === report.id) return {...report, ...editedReport}
                return report;
            });
            setReports(editedReportList)
            enqueueSnackbar('Riport szerkesztve!', {variant: 'success'})
        })
    }

    const destroyReport = () => {
        deleteReport(current).then(() => {
            setReports(reports.filter(r => r.id !== current))
            enqueueSnackbar('Riport törölve!', {variant: 'success'})
        })
    }

    return <ResponsiveDrawer label='Riportok' buttons={[{
        name: 'Új riport',
        props: {
            variant: 'contained',
            endIcon: <AddCircleOutlineRoundedIcon/>,
            onClick: () => setNewReportDialogOpen(true)
        }
    }]}>
        <div>
            <Grid container spacing={2}>
                {reports?.map((r, i) => <Grid key={`report_${i}`} item xs={12} sm={6} md={4} lg={3}>
                    <Card sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        <CardContent>
                            <Stack spacing={1}>
                                <Typography variant="headline6" color={theme.palette.primary[600]}>
                                    {r?.name}
                                </Typography>

                                <Stack direction='row' spacing={1} sx={{alignItems: 'center'}}>
                                    <Avatar>
                                        <StorageRoundedIcon/>
                                    </Avatar>
                                    <Typography variant="button" color={theme.palette.secondary[600]}>
                                        {r?.model_name}
                                    </Typography>
                                </Stack>

                            </Stack>

                        </CardContent>
                        <CardActions>
                            <Button size="medium" variant='contained' color='success'
                                    onClick={() => history.push(`report/${r?.id}`)}>Megnyitás</Button>
                            <IconButton onClick={() => {
                                setCurrent(r?.id)
                                setEditReportDialogOpen(true)
                            }}>
                                <EditRoundedIcon/>
                            </IconButton>
                            <IconButton onClick={() => {
                                setCurrent(r?.id)
                                setDeleteReportDialogOpen(true)
                            }} color='error'>
                                <DeleteRoundedIcon/>
                            </IconButton>
                        </CardActions>
                    </Card>
                </Grid>)}
            </Grid>
        </div>

        <Loading isLoading={loading}/>

        {/*{permissions.includes('add_report') && <BasicSpeedDial onClick={() => setNewReportDialogOpen(true)}/>}*/}

        <DinoDialog title='Riport hozzáadása'
                    subtitle='Riport hozzáadása'
                    open={isNewReportDialogOpen}
                    handleClose={() => setNewReportDialogOpen(false)}
                    actions={<>
                        <Button variant='outlined' style={{padding: 15}}
                                onClick={() => setNewReportDialogOpen(false)}>mégsem</Button>
                        <Button variant='contained' style={{padding: 15}} onClick={() => {
                            const data = reportForm.current.getData()
                            if (data) {
                                createReport(data)
                                setNewReportDialogOpen(false)
                            }
                        }} endIcon={<EditIcon/>}>Szerkesztés</Button>
                    </>}>
            <ReportData reference={reportForm}/>
        </DinoDialog>

        <DinoDialog title='Riport szerkesztése'
                    subtitle='Riport szerkesztése'
                    open={isEditReportDialogOpen}
                    handleClose={() => setEditReportDialogOpen(false)}
                    actions={<>
                        <Button variant='outlined' style={{padding: 15}}
                                onClick={() => setEditReportDialogOpen(false)}>mégsem</Button>
                        <Button variant='contained' style={{padding: 15}} onClick={() => {
                            const data = reportForm.current.getData()
                            if (data) {
                                editReport(data)
                                setEditReportDialogOpen(false)
                            }
                        }} endIcon={<EditIcon/>}>Szerkesztés</Button>
                    </>}>
            <ReportData reference={reportForm} obj={findReport(current)}/>
        </DinoDialog>

        <DinoDialog title='Riport törlése'
                    subtitle='Riport törlése'
                    open={isDeleteReportDialogOpen}
                    handleClose={() => setDeleteReportDialogOpen(false)}
                    actions={<>
                        <Button variant='outlined' style={{padding: 15}}
                                onClick={() => setDeleteReportDialogOpen(false)}>mégsem</Button>
                        <Button variant='contained' color='error' style={{padding: 15}} onClick={() => {
                            setDeleteReportDialogOpen(false)
                            destroyReport()
                        }} endIcon={<DeleteIcon/>}>Törlés</Button>
                    </>}/>

    </ResponsiveDrawer>
}

export default Reports