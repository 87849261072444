import React, {useState} from 'react';
import {PieChart, Pie, Sector, Cell, ResponsiveContainer} from 'recharts';
import thousandSeparator from "../../utils/NumberUtils";


const MyPieChart = ({data, dataKey='value', nameKey='uf_name', unit='Ft'}) => {
    const [state, setState] = useState({activeIndex: 0})

    const onPieEnter = (_, index) => {
        setState({
            activeIndex: index,
        });
    }

    const COLORS = [
        '#3A1C71',
        '#FF7F50',
        '#87CEEB',
        '#32CD32',
        '#FFD700',
        '#9932CC',
        '#FF6347',
        '#00CED1',
        '#FF4500',
        '#8A2BE2'
    ]

    // const RADIAN = Math.PI / 180;
    //
    // const findIndexByName = (name) => {
    //     return data.findIndex(d => d[nameKey] === name)
    // }

    const renderActiveShape = (props) => {

        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{props.name}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(Százalék ${(percent * 100).toFixed(2)}%)`}
                </text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={35} textAnchor={textAnchor} fill="#999">
                    {`(Érték ${thousandSeparator(value)} ${unit})`}
                </text>
            </g>
        );
    };

    return (
        <div style={{position: 'relative', width: '100%', height: '600px'}}>
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                }}
            ><ResponsiveContainer width="100%" height="100%">
                <PieChart width={800} height={1200}>
                    <Pie data={data}
                         activeIndex={state.activeIndex}
                         activeShape={renderActiveShape}
                         dataKey={dataKey}
                         nameKey={nameKey}
                         cx="50%"
                         cy="50%"
                         outerRadius={200}
                         fill="#8884d8"
                         onMouseEnter={onPieEnter}>
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            </div>
        </div>
    );
}

export default MyPieChart
