import React, { useState } from 'react';
import {Card, CardContent, Typography, Chip, IconButton, Menu, MenuItem, Stack} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GoogleBarChart from "../../components/charts/GoogleBarChart";
import thousandSeparator from "../../utils/NumberUtils";
import GooglePieChart from "../../components/charts/GooglePieChart";
import GoogleLineChart from "../../components/charts/GoogleLineChart";

const ReportCard = ({ r, setCurrent, setEditReportPartDialogOpen, setDeleteReportPartDialogOpen }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        setCurrent(r?.id);
        setEditReportPartDialogOpen(true);
        handleMenuClose();
    };

    const handleDelete = () => {
        setCurrent(r?.id);
        setDeleteReportPartDialogOpen(true);
        handleMenuClose();
    };

    const resultFormatMap = (resultFormat, result) => {
        if (!result) return 'Hibás kimutatás!!!'
        switch (resultFormat) {
            case 'barchart':
                return <GoogleBarChart height='400px' options={{}} data={result?.result}/>
            case 'linechart':
                return <GoogleLineChart options={{}} data={result?.result}/>
             case 'piechart':
                 return <GooglePieChart options={{}} data={result?.result}/>
            default:
                return <Typography gutterBottom variant="p" component="div">{'Eredmény: ' + thousandSeparator(result?.result)}</Typography>
        }
    }

    return (
        <Card sx={{ maxWidth: '100%' }}>
            <CardContent>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography gutterBottom variant="h5" component="div">
                        Név: {r?.name}
                    </Typography>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleMenuOpen}
                        sx={{ zIndex: 999 }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Stack>


                {/*{JSON.stringify(r)}*/}
                {resultFormatMap(r?.result_type?.result_format, r)}

                <Typography gutterBottom variant="body1" component="div">
                    Kimutatás fajtája: {r?.result_type?.name}
                </Typography>

                <Typography gutterBottom variant="body1" component="div">
                    Mező(k): {r?.fields?.fields?.map(f => <Chip key={f} size='small' label={f} />)}
                </Typography>
            </CardContent>



            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        maxHeight: 48 * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem onClick={handleEdit}>Szerkesztés</MenuItem>
                <MenuItem onClick={handleDelete}>Törlés</MenuItem>
            </Menu>
        </Card>
    );
};

export default ReportCard;
