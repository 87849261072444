import AuthDrawer from "../../components/AuthDrawer";
import useCallDataApi from "../../hooks/data";
import {useEffect, useRef, useState} from "react";
import DinoDataGrid from "../../components/DataGrid";
import thousandSeparator from "../../utils/NumberUtils";
import {Box, Button, FormControl, Grid, MenuItem, Select, Stack, Typography} from "@mui/material";
import Loading from "../../components/Loading";
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import DinoDialog from "../../components/Dialog";
import ControllingModifierData from "./ControllingModifierData";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import {GridActionsCellItem} from "@mui/x-data-grid";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import {getFormData} from "../../utils/formUtils";

const ControllingMain = () => {
    const {postData} = useCallDataApi('general-ledger-analytics')
    const {
        getData: fetchControllingModifier,
        postData: newControllingModifier,
        updateData: updateControllingModifier,
        deleteData: deleteControllingModifier
    } = useCallDataApi('controlling-modifier')
    const [data, setData] = useState([])
    const [dataSum, setDataSum] = useState([])
    const [controllingModifiers, setControllingModifiers] = useState([])
    const [showModifiers, setShowModifiers] = useState(false)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [selectedClass, setSelectedClass] = useState(5)
    const [modifierEditOpen, setModifierEditOpen] = useState(false)
    const [modifierNewOpen, setModifierNewOpen] = useState(false)
    const [modifierDeleteOpen, setModifierDeleteOpen] = useState(false)
    const [current, setCurrent] = useState(-1)
    const [loading, setLoading] = useState({yearlyLoading: true, yearlySumLoading: true, modifierLoading: true})
    const modifierRef = useRef(null)

    document.title = 'Kontrolling'

    useEffect(() => {
        setLoading(prev => ({...prev, modifierLoading: true}))
        fetchControllingModifier()
            .then(d => setControllingModifiers(d))
            .finally(() => setLoading(prev => ({...prev, modifierLoading: false})))
    }, [])

    useEffect(() => {
        setLoading(prev => ({...prev, yearlySumLoading: true}))
        postData('controlling_main_yearly_sum/', {year: selectedYear})
            .then(d => setDataSum(d))
            .finally(() => setLoading(prev => ({...prev, yearlySumLoading: false})))
    }, [selectedYear])

    useEffect(() => {
        setLoading(prev => ({...prev, yearlyLoading: true}))
        postData('controlling_main_yearly/', {year: selectedYear, mirr_class: selectedClass})
            .then(d => setData(d))
            .finally(() => setLoading(prev => ({...prev, yearlyLoading: false})))
    }, [selectedYear, selectedClass])

    const addControllingModifier = () => {
        const formData = getFormData(modifierRef.current)
        setLoading(prev => ({...prev, modifierLoading: true}))
        newControllingModifier('', formData)
            .then(d => setControllingModifiers([...controllingModifiers, d]))
            .finally(() => setLoading(prev => ({...prev, modifierLoading: false})))
    }

    const editControllingModifier = () => {
        const formData = getFormData(modifierRef.current)
        setLoading(prev => ({...prev, modifierLoading: true}))
        updateControllingModifier(`${current}/`, formData)
            .then(editedModifier => {
                const editedModifierList = controllingModifiers.map(modifier => {
                    if (current === modifier.id) return {...modifier, ...editedModifier}
                    return modifier;
                })
                setControllingModifiers(editedModifierList)
            })
            .finally(() => setLoading(prev => ({...prev, modifierLoading: false})))
    }

    const removeControllingModifier = () => {
        setLoading(prev => ({...prev, modifierLoading: true}))
        deleteControllingModifier(current)
            .then(d => setControllingModifiers([...controllingModifiers.filter(m => m?.id !== current)]))
            .finally(() => setLoading(prev => ({...prev, modifierLoading: false})))
    }

    const columns = [
        {headerName: 'Tétel megnevezése', field: 'mirr_name1', width: 300},
        {
            headerName: 'Január',
            field: '1',
            width: 100,
            renderCell: params => thousandSeparator(params.row['1']) + ' Ft'
        },
        {
            headerName: 'Február',
            field: '2',
            width: 100,
            renderCell: params => thousandSeparator(params.row['2']) + ' Ft'
        },
        {
            headerName: 'Március',
            field: '3',
            width: 100,
            renderCell: params => thousandSeparator(params.row['3']) + ' Ft'
        },
        {
            headerName: 'Április',
            field: '4',
            width: 100,
            renderCell: params => thousandSeparator(params.row['4']) + ' Ft'
        },
        {headerName: 'Május', field: '5', width: 100, renderCell: params => thousandSeparator(params.row['5']) + ' Ft'},
        {
            headerName: 'Június',
            field: '6',
            width: 100,
            renderCell: params => thousandSeparator(params.row['6']) + ' Ft'
        },
        {
            headerName: 'Július',
            field: '7',
            width: 100,
            renderCell: params => thousandSeparator(params.row['7']) + ' Ft'
        },
        {
            headerName: 'Augusztus',
            field: '8',
            width: 100,
            renderCell: params => thousandSeparator(params.row['8']) + ' Ft'
        },
        {
            headerName: 'Szeptember',
            field: '9',
            width: 100,
            renderCell: params => thousandSeparator(params.row['9']) + ' Ft'
        },
        {
            headerName: 'Október',
            field: '10',
            width: 100,
            renderCell: params => thousandSeparator(params.row['10']) + ' Ft'
        },
        {
            headerName: 'November',
            field: '11',
            width: 100,
            renderCell: params => thousandSeparator(params.row['11']) + ' Ft'
        },
        {
            headerName: 'December',
            field: '12',
            width: 100,
            renderCell: params => thousandSeparator(params.row['12']) + ' Ft'
        },
        {
            headerName: 'Összesen',
            field: 'total',
            width: 100,
            renderCell: params => thousandSeparator(params.row.total) + ' Ft'
        },
    ]
    const controllingColumns = [
        {
            field: "manage",
            headerName: "Manage",
            sortable: false,
            type: "actions",
            getActions: (params) => [<GridActionsCellItem
                icon={<DeleteForeverRoundedIcon/>}
                onClick={() => {
                    setCurrent(params.row.id)
                    setModifierDeleteOpen(true)
                }}
                label="Törlés"
                showInMenu={true}
            />, <GridActionsCellItem
                icon={<EditRoundedIcon/>}
                onClick={() => {
                    setCurrent(params.row.id)
                    setModifierEditOpen(true)
                }}
                label="Szerkesztés"
                showInMenu={true}
            />,]
        },
        {headerName: 'Megnevezése', field: 'name', width: 300},
        {headerName: 'Dátum', field: 'date', width: 100},
        {
            headerName: 'Összeg',
            field: 'amount',
            type: 'number',
            width: 100,
            renderCell: params => thousandSeparator(params.row.amount) + ' Ft'
        },
        {headerName: 'Számlaosztály', field: 'mirr_class', width: 100},
    ]

    const sumModifiersForMonth = (month, considerClass) => {
        const modifiersForMonth = controllingModifiers.filter(c => {
            const m = parseInt(c?.date.split('-')[1])
            if (considerClass) return m === month && c?.mirr_class === selectedClass
            return m === parseInt(month)
        })
        return modifiersForMonth.map(m => m?.amount).reduce((partialSum, a) => partialSum + a, 0)
    }

    const sumForMonth = (month) => {
        let sum = 0
        let modifierAmount = 0
        if (showModifiers) {
            modifierAmount = sumModifiersForMonth(month, true)
        }
        data?.filter(d => d[month] !== undefined).forEach(d => sum += d[month])
        return parseInt(sum) + modifierAmount
    }

    const sumForAllClassesByMonth = (month) => {
        let modifierAmount = 0
        if (showModifiers) {
            modifierAmount = sumModifiersForMonth(month, false)
        }
        let newVar = dataSum?.find(d => d.month === parseInt(month))?.total_sum || 0;
        return parseInt(newVar) + modifierAmount
    }

    const getModifier = (id) => controllingModifiers.find(m => m?.id === id)


    const months = {
        'Január': '1',
        'Február': '2',
        'Március': '3',
        'Április': '4',
        'Május': '5',
        'Június': '6',
        'Július': '7',
        'Augusztus': '8',
        'Szeptember': '9',
        'Október': '10',
        'November': '11',
        'December': '12'
    }

    return <AuthDrawer label='Kontrolling'>
        <Stack marginBottom={3} className='gradient-card'>
            <Typography variant='headline6'>Összesítő {selectedYear} évhez</Typography>
            <Grid container spacing={2} marginTop={2}>
                {Object.keys(months).map(m => <Grid key={m} item xs={2} xl={2}>
                    <Typography variant='overline'>{m}</Typography>
                    <Typography
                        variant='subtitle2'>{thousandSeparator(sumForAllClassesByMonth(months[m]))} Ft</Typography>
                </Grid>)}
            </Grid>
        </Stack>
        <Stack marginBottom={3} className='white-card'>
            <Typography variant='headline6'>Összesítő {selectedClass}.
                számlaosztályhoz {selectedYear} évben</Typography>
            <Grid container spacing={2} marginTop={2}>
                {Object.keys(months).map(m => <Grid key={m} item xs={2} xl={2}>
                    <Typography variant='overline'>{m}</Typography>
                    <Typography variant='subtitle2'>{thousandSeparator(sumForMonth(months[m]))} Ft</Typography>
                </Grid>)}
            </Grid>
        </Stack>

        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: {xs: 'column', lg: 'row'},
            justifyContent: {xs: 'center', md: 'space-between'},
            marginBottom: 2,
            gap: 3,
            marginTop: 3,
        }}>
            <Box sx={{
                display: 'flex',
                gap: 3,
            }}>
                <Button variant='outlined'
                        startIcon={showModifiers ? <VisibilityOffRoundedIcon/> : <VisibilityRoundedIcon/>}
                        onClick={() => setShowModifiers(prev => !prev)}>Vonal alatti
                    tételek {showModifiers ? 'elrejtése' : 'mutatása'}</Button>
                <Button variant='outlined' startIcon={<AddCircleRoundedIcon/>} onClick={() => setModifierNewOpen(true)}>Vonal
                    alatti tétel hozzáadása</Button>
            </Box>
            <Box sx={{
                display: 'flex',
                gap: 3,
            }}>
                <FormControl>
                    <Select
                        id="year-select"
                        value={selectedYear}
                        onChange={e => setSelectedYear(e.target.value)}
                    >
                        <MenuItem value={2023}>2023</MenuItem>
                        <MenuItem value={2024}>2024</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <Select
                        id="class-select"
                        value={selectedClass}
                        onChange={e => setSelectedClass(e.target.value)}
                    >
                        <MenuItem value={5}>5-ös számlaosztály</MenuItem>
                        <MenuItem value={8}>8-as számlaosztály</MenuItem>
                        <MenuItem value={9}>9-es számlaosztály</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Box>
        <DinoDataGrid rows={data} columns={columns}/>
        <Box marginTop={3} marginBottom={2}>
            <Typography variant='headline6'>Vonal alatti tételek:</Typography>
        </Box>
        <DinoDataGrid rows={controllingModifiers} columns={controllingColumns}/>

        <DinoDialog
            title='Új vonal alatti tétel'
            subtitle='Írd be a vonal alatti tétel adatait'
            open={modifierNewOpen}
            handleClose={() => setModifierNewOpen(false)}
            actions={<Stack direction='row' spacing={2}>
                <Button variant='outlined' onClick={() => setModifierNewOpen(false)}>Mégsem</Button>
                <Button variant='contained' endIcon={<AddCircleRoundedIcon/>} onClick={() => {
                    setModifierNewOpen(false)
                    addControllingModifier()
                }}>Hozzáadás</Button>
            </Stack>}>
            <ControllingModifierData reference={modifierRef}/>
        </DinoDialog>

        <DinoDialog
            title='Vonal alatti tétel szerkesztése'
            subtitle='Írd be a vonal alatti tétel adatait'
            open={modifierEditOpen}
            handleClose={() => setModifierEditOpen(false)}
            actions={<Stack direction='row' spacing={2}>
                <Button variant='outlined' onClick={() => setModifierEditOpen(false)}>Mégsem</Button>
                <Button variant='contained' endIcon={<EditRoundedIcon/>} onClick={() => {
                    setModifierEditOpen(false)
                    editControllingModifier()
                }}>Szerkesztés</Button>
            </Stack>}>
            <ControllingModifierData reference={modifierRef} modifier={getModifier(current)}/>
        </DinoDialog>

        <DinoDialog
            title='Vonal alatti tétel törlése'
            subtitle='Biztos szeretnéd kitörölni ezt?'
            open={modifierDeleteOpen}
            handleClose={() => setModifierDeleteOpen(false)}
            actions={<Stack direction='row' spacing={2}>
                <Button variant='outlined' onClick={() => setModifierDeleteOpen(false)}>Mégsem</Button>
                <Button variant='contained' endIcon={<DeleteForeverRoundedIcon/>} onClick={() => {
                    setModifierDeleteOpen(false)
                    removeControllingModifier()
                }}>Törlés</Button>
            </Stack>}/>

        <Loading isLoading={Object.values(loading).includes(true)}/>
    </AuthDrawer>
}

export default ControllingMain