import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";

const menuItems = [
    {name: 'Alaptábla', url: '/controlling', icon: <SearchRoundedIcon/>, permissions: ['view_generalledgeranalytics']},
    {name: 'Riportok', url: '/report', icon: <AssessmentRoundedIcon/>, permissions: ['view_report']},
    {name: 'Kész riportok', url: '/finished-report', icon: <AssessmentRoundedIcon/>, permissions: ['view_report']},
    {name: 'Ügyintézői jutalék', url: '/commission', icon: <PeopleRoundedIcon/>, permissions: ['view_customuser']},
    {name: 'Mentett ügyintézői jutalék', url: '/saved-commissions', icon: <PeopleRoundedIcon/>, permissions: ['view_savedcommission']},
    {name: 'Kontrolling', url: '/controlling-main', icon: <MonetizationOnRoundedIcon/>, permissions: ['view_generalledgeranalytics']},
    {name: 'Ügyintézői eredményesség', url: '/employee-effectiveness', icon: <QueryStatsRoundedIcon/>, permissions: ['view_customuser']},
    {name: 'Ügyfél eredményesség', url: '/select-view', icon: <BubbleChartRoundedIcon/>, permissions: ['view_partner']},
    {name: 'Ügyfél forgalom', url: '/partner-traffic-effectiveness', icon: <BubbleChartRoundedIcon/>, permissions: ['view_partner']},
]

export default menuItems