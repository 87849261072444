import React from "react";
import { Chart } from "react-google-charts";


const GoogleBarChart = ({data, options, height}) => {

    return (
        <Chart
            chartType="BarChart"
            width="100%"
            height={height}
            data={data}
            options={options}
        />
    );
}

export default GoogleBarChart