import {useHistory, useParams} from "react-router-dom";
import {Autocomplete, Box, FormControl, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import {useEffect, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {hu} from "date-fns/locale";
import {format} from "date-fns";
import useCallDataApi from "../../../hooks/data";
import thousandSeparator from "../../../utils/NumberUtils";
import DinoDataGrid from "../../../components/DataGrid";
import Loading from "../../../components/Loading";
import AuthDrawer from "../../../components/AuthDrawer";

const GroupCompare = () => {
    const today = new Date()
    const {getData: fetchPartners, postData: postPartners} = useCallDataApi('company-groups')
    const [firstPartner, setFirstPartner] = useState({})
    const [secondPartner, setSecondPartner] = useState({})
    const [firstPartnerData, setFirstPartnerData] = useState([])
    const [secondPartnerData, setSecondPartnerData] = useState([])
    const [fromDate, setFromDate] = useState(new Date(`${today.getFullYear()}-01-01`))
    const [endDate, setEndDate] = useState(today)
    const [selectedMode, setSelectedMode] = useState('margin')
    const [loading, setLoading] = useState(true)
    const params = useParams()
    const history = useHistory()

    useEffect(() => {
        fetchPartners(`${params.first}/`).then(p => setFirstPartner(p)).finally(() => setLoading(false))
        fetchPartners(`${params.second}/`).then(p => setSecondPartner(p)).finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        postPartners('partner_data_for_range/', {
            partner: params.first,
            start_date: format(fromDate, 'yyyy-MM-dd'),
            end_date: format(endDate, 'yyyy-MM-dd'),
            mode: selectedMode
        }).then(d => setFirstPartnerData(d))
        postPartners('partner_data_for_range/', {
            partner: params.second,
            start_date: format(fromDate, 'yyyy-MM-dd'),
            end_date: format(endDate, 'yyyy-MM-dd'),
            mode: selectedMode
        }).then(d => setSecondPartnerData(d))
    }, [selectedMode, fromDate, endDate])

    const columns = [
        {headerName: 'Tétel megnevezése', field: 'mirr_name1', width: 300},
        {headerName: 'Összesen', field: `total_sum_${selectedMode}`, width: 300, renderCell: p => `${thousandSeparator(p.row[`total_sum_${selectedMode}`])} Ft`},
        {headerName: 'Darabszám', field: 'total_count', width: 300, renderCell: p => `${thousandSeparator(p.row.total_count)} db`},
    ]

    const sumTotalSum = (pd) => {
        let s = 0
        pd.forEach(p => s += p[`total_sum_${selectedMode}`])
        return s
    }

    const sumTotalCount = (pd) => {
        let s = 0
        pd.forEach(p => s += p?.total_count)
        return s
    }

    return <AuthDrawer label={`${firstPartner?.name} vs ${secondPartner?.name}`}>
            <Stack direction='row'>
                <Button variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}
                        onClick={() => history.goBack()}>Vissza</Button>
            </Stack>
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 2,
                gap: 3,
                marginTop: 3
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: 3,
                }}>
                    {/*<Button variant='outlined' startIcon={showModifiers ? <VisibilityOffRoundedIcon/> : <VisibilityRoundedIcon/>} onClick={() => setShowModifiers(prev => !prev)}>Vonal alatti tételek {showModifiers ? 'elrejtése' : 'mutatása'}</Button>*/}
                    {/*<Button variant='outlined' startIcon={<AddCircleRoundedIcon/>} onClick={() => setModifierNewOpen(true)}>Vonal alatti tétel hozzáadása</Button>*/}
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: 3,
                }}>
                    <FormControl>
                        <Select
                            id="margin-traffic-select"
                            value={selectedMode}
                            onChange={e => setSelectedMode(e.target.value)}
                        >
                            <MenuItem value='margin'>Árrés</MenuItem>
                            <MenuItem value='traffic'>Forgalom</MenuItem>
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hu}>
                        <Stack spacing={2} direction='row' alignItems='center'>
                            <DatePicker
                                inputFormat="yyyy-MM-dd"
                                mask='____-__-__'
                                value={fromDate}
                                onChange={(v) => setFromDate(v)}
                                renderInput={(params) => <TextField name='from_date' {...params} />}
                            />
                            <Typography variant='body1' color='#38556A'>-</Typography>
                            <DatePicker
                                inputFormat="yyyy-MM-dd"
                                mask='____-__-__'
                                value={endDate}
                                onChange={(v) => setEndDate(v)}
                                renderInput={(params) => <TextField name='until_date' {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Box>
            </Box>

            <Stack direction='row' gap={2} marginTop={2}>
                <Box>
                    <Stack marginBottom={3} className='gradient-card' spacing={1} >
                        <Typography variant='headline6'>Összesen</Typography>
                        <Typography variant='overline'>Érték</Typography>
                        <Typography variant='subtitle2'>{thousandSeparator(sumTotalSum(firstPartnerData))} Ft</Typography>
                        <Typography variant='overline'>Darabszám</Typography>
                        <Typography variant='subtitle2'>{thousandSeparator(sumTotalCount(firstPartnerData))} db</Typography>
                    </Stack>
                    <DinoDataGrid rows={firstPartnerData} columns={columns}/>
                </Box>
                <Box>
                    <Stack marginBottom={3} className='gradient-card' spacing={1} >
                        <Typography variant='headline6'>Összesen</Typography>
                        <Typography variant='overline'>Érték</Typography>
                        <Typography variant='subtitle2'>{thousandSeparator(sumTotalSum(secondPartnerData))} Ft</Typography>
                        <Typography variant='overline'>Darabszám</Typography>
                        <Typography variant='subtitle2'>{thousandSeparator(sumTotalCount(secondPartnerData))} db</Typography>
                    </Stack>
                    <DinoDataGrid rows={secondPartnerData} columns={columns}/>
                </Box>

            </Stack>

        <Loading isLoading={loading}/>
    </AuthDrawer>
}

export default GroupCompare