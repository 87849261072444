import { Route, Redirect } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const PrivateRoute = ({ children, requiredPermissions=[],...rest }) => {
    const { user, permissions } = useContext(AuthContext);
    const hasEveryPermission = requiredPermissions.every(perm => permissions?.includes(perm));
    return <Route {...rest}>{!user ? <Redirect to="/login" /> : (!hasEveryPermission ? <Redirect to="/" /> : children)}</Route>;
};

export default PrivateRoute;