import React from 'react';
import {Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import thousandSeparator from "../../utils/NumberUtils";
import {getMonthName} from "../../utils/miscUtils";

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: "10px", border: "1px solid #ccc" }}>
                <p className="label">{`${getMonthName(label)}:`}</p>
                {payload.map((entry, index) => (
                    <p key={`item-${index}`} style={{ color: entry.color }}>
                        {`${entry.name}: ${thousandSeparator(entry.value)} Ft`}
                    </p>
                ))}
            </div>
        );
    }

    return null;
};

const addMissingMonths = (data) => {
    const allMonths = Array.from({ length: 12 }, (_, i) => i + 1); // [1, 2, ..., 12]
    return allMonths.map(month => {
        const existingMonthData = data.find(d => d.month === month);
        return existingMonthData ? existingMonthData : {month, sum_traffic: 0, sum_margin: 0};
    });
};

const MonthlyPartnerBarChart = ({data}) => {
    const completeData = addMissingMonths(data);

    return (
        <div style={{position: 'relative', width: '100%', height: '600px'}}>
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                }}
            >
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={completeData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" tickFormatter={(value) => getMonthName(value)} />
                        <YAxis tickFormatter={(value) => thousandSeparator(value)} />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Bar name='Árrés' dataKey="sum_margin" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                        <Bar name='Forgalom' dataKey="sum_traffic" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default MonthlyPartnerBarChart;
