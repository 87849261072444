import {useContext, useMemo} from "react";
import AuthContext from "../context/AuthContext";
import {useLocation} from "react-router-dom";

export function useAuthHeader() {
    const { authTokens } = useContext(AuthContext);
    return {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + String(authTokens?.access),
        },
        formDataHeaders: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + String(authTokens?.access),
        },
    };
}

export function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}