import AuthDrawer from "../../components/AuthDrawer";
import {Avatar, Card, CardActions, CardContent, Stack, Typography, useTheme} from "@mui/material";
import {stringAvatar} from "../../utils/miscUtils";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

const SelectView = () => {
    const theme = useTheme()
    const history = useHistory()

    document.title = 'Ügyfél eredményesség'

    return <AuthDrawer label='Válasszon nézetet'>
        <Stack spacing={2} sx={{direction: {xs: 'column', md: 'row'}}}>


            <Card>
                <CardContent>
                    <Stack direction='row' spacing={4}>
                        <Stack spacing={1}>
                            <Typography variant="headline6" color={theme.palette.primary[600]}>
                                Cégcsoportok
                            </Typography>
                            <Typography variant="button" color={theme.palette.secondary[600]}>
                                Az adminisztrátori felületen összeállított cégcsoportokat láthatja itt.
                            </Typography>
                        </Stack>
                    </Stack>
                </CardContent>
                <CardActions>
                    <Button variant='outlined'
                            onClick={() => history.push('company-groups')}>Megnyitás</Button>
                </CardActions>
            </Card>


            <Card>
                <CardContent>
                    <Stack direction='row' spacing={4}>
                        <Stack spacing={1}>
                            <Typography variant="headline6" color={theme.palette.primary[600]}>
                                Partnerek
                            </Typography>
                            <Typography variant="button" color={theme.palette.secondary[600]}>
                                Magánszemélyek és cégek
                            </Typography>
                        </Stack>
                    </Stack>
                </CardContent>
                <CardActions>
                    <Button variant='outlined'
                            onClick={() => history.push('partner-effectiveness')}>Megnyitás</Button>
                </CardActions>
            </Card>
        </Stack>
    </AuthDrawer>

}

export default SelectView