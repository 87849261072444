import AuthDrawer from "../../components/AuthDrawer";
import {
    Autocomplete,
    Box,
    Button,
    createFilterOptions,
    FormControl, Grid,
    MenuItem,
    Select,
    Stack,
    TextField, Typography
} from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DinoDataGrid from "../../components/DataGrid";
import {useState, useEffect, useRef} from "react";
import thousandSeparator from "../../utils/NumberUtils";
import useCallDataApi from "../../hooks/data";
import Loading from "../../components/Loading";
import {useParams} from "react-router-dom";
import MyPieChart from "./PieChart";
import DinoDialog from "../../components/Dialog";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {getFormData} from "../../utils/formUtils";
import ExpandSector from "./ExpandSector";

const EmployeeEffectivenessDetail = () => {
    const today = new Date()
    const {getData: fetchPartners, postData: postPartners} = useCallDataApi('partners')
    const {getData: fetchModifiers, postData: postModifier} = useCallDataApi('effectiveness-modifier')
    const {getData: fetchEffectiveness, postData: postEffectiveness} = useCallDataApi('employee-effectiveness')
    const {getData: getUser, postData: postUser} = useCallDataApi('users')
    const [user, setUser] = useState({})
    const [selectedYear, setSelectedYear] = useState(today.getFullYear())
    const [selectedMonth, setSelectedMonth] = useState(today.getMonth())
    const [selectedSectorMonth, setSelectedSectorMonth] = useState(today.getMonth())
    const [selectedJobMonth, setSelectedJobMonth] = useState(today.getMonth())
    const [selectedMode, setSelectedMode] = useState('margin')
    const [selectedViewMode, setSelectedViewMode] = useState('Ft')
    const [partnerInput, setPartnerInput] = useState('')
    const [selectedPartner, setSelectedPartner] = useState({id: null})
    const [partners, setPartners] = useState([])
    const [data, setData] = useState([])
    const [chartData, setChartData] = useState([])
    const [fixedData, setFixedData] = useState([])
    const [sectorData, setSectorData] = useState([])
    const [modifierData, setModifierData] = useState([])
    const [modDialogOpen, setModDialogOpen] = useState(false)
    const [editedModifier, setEditedModifier] = useState({
        remote_expense: 0,
        duty: 0,
        daily_fee: 0,
        previous_deduction: 0
    })
    const [jobCtrl, setJobCtrl] = useState({avg_worktime: 0, avg_productive: 0, avg_offline: 0, avg_keyboard: 0})
    const [loading, setLoading] = useState({partnersLoading: true, userLoading: true, sectorLoading: true})
    const params = useParams()
    const modRef = useRef()

    const yearList = Array.from({length: today.getFullYear() - 2022}, (_, index) => 2023 + index)

    useEffect(() => {
        setLoading(prev => ({...prev, userLoading: true}))
        getUser(params.id)
            .then(u => {
                setUser(u)
                document.title = u.name
            })
            .finally(() => setLoading(prev => ({...prev, userLoading: false})))
        fetchEffectiveness(`get_fixed_data/?user=${params.id}&year=${selectedYear}`).then(d => setFixedData(d.filter(r => r?.color !== 'red'))).finally(() => console.log('finished'))
        fetchModifiers(`get_for_user_year/?user=${params.id}&year=${selectedYear}`).then(d => setModifierData(d)).finally(() => console.log('finished'))
    }, [])

    useEffect(() => {
        setLoading(prev => ({...prev, partnersLoading: true}))
        postPartners('get_for_user_and_year/', {year: selectedYear, user: params.id, mode: selectedMode})
            .then(p => setPartners(p))
            .finally(() => setLoading(prev => ({...prev, partnersLoading: false})))
    }, [selectedYear])

    useEffect(() => {
        setLoading(prev => ({...prev, sectorLoading: true}))
        postEffectiveness('business_sector_breakdown/', {
            year: selectedYear,
            month: selectedSectorMonth,
            user: params.id
        })
            .then(p => {
                setSectorData(p)
                console.log(p)
            })
            .finally(() => setLoading(prev => ({...prev, sectorLoading: false})))
    }, [selectedYear, selectedSectorMonth])

    useEffect(() => {
        postEffectiveness('get_total_for_partners/', {user: params.id, year: selectedYear}).then(d => setChartData(d))
    }, [selectedYear])

    useEffect(() => {
        setLoading(prev => ({...prev, generalLoading: true}))
        if (selectedViewMode === 'db' && selectedMode !== 'traffic') setSelectedMode('traffic')
        postEffectiveness('get_with_filter_groups/', {
            user: params.id,
            year: selectedYear,
            mode: selectedMode,
            partner: selectedPartner?.id,
            view: selectedViewMode
        })
            .then(d => {
                setData(d)
                // console.log(d)
            })
            .finally(() => setLoading(prev => ({...prev, generalLoading: false})))
    }, [selectedPartner, selectedYear, selectedMode, selectedViewMode])

    const mixInModifiers = () => {
        const newFixed = []
        for (let fixed of fixedData) {
            const mod = modifierData.find(m => fixed?.salary_month === m?.month)
            if (mod) {
                newFixed.push({...fixed, ...mod})
            } else {
                newFixed.push(fixed)
            }
        }
        setFixedData(newFixed)
    }

    useEffect(() => {
        if (fixedData && modifierData) mixInModifiers()
    }, [modifierData])

    useEffect(() => {
        postEffectiveness('job_ctrl/', {
            user: params.id,
            year: selectedYear,
            month: selectedJobMonth
        }).then(d => setJobCtrl(d))
    }, [selectedJobMonth, selectedYear])

    useEffect(() => {
        const mod = modifierData.find(m => m.month == selectedMonth)
        if (mod) setEditedModifier(mod)
        else setEditedModifier({
            remote_expense: 0,
            duty: 0,
            daily_fee: 0,
            previous_deduction: 0
        })
    }, [selectedMonth])

    const editModifier = () => {
        const formData = getFormData(modRef.current)
        postModifier('update_modifier/', {...formData, year: selectedYear, user: params.id}).then(d => {
            const filteredList = modifierData.filter(m => m.id !== d?.id)
            setModifierData([...filteredList, d])
        })
    }

    const columns = [
        {headerName: 'Tétel megnevezése', field: 'mirr_name1', width: 300},
        {
            headerName: 'Január',
            field: '1',
            width: 100,
            renderCell: params => thousandSeparator(params.row['1']) + ' ' + selectedViewMode
        },
        {
            headerName: 'Február',
            field: '2',
            width: 100,
            renderCell: params => thousandSeparator(params.row['2']) + ' ' + selectedViewMode
        },
        {
            headerName: 'Március',
            field: '3',
            width: 100,
            renderCell: params => thousandSeparator(params.row['3']) + ' ' + selectedViewMode
        },
        {
            headerName: 'Április',
            field: '4',
            width: 100,
            renderCell: params => thousandSeparator(params.row['4']) + ' ' + selectedViewMode
        },
        {
            headerName: 'Május',
            field: '5',
            width: 100,
            renderCell: params => thousandSeparator(params.row['5']) + ' ' + selectedViewMode
        },
        {
            headerName: 'Június',
            field: '6',
            width: 100,
            renderCell: params => thousandSeparator(params.row['6']) + ' ' + selectedViewMode
        },
        {
            headerName: 'Július',
            field: '7',
            width: 100,
            renderCell: params => thousandSeparator(params.row['7']) + ' ' + selectedViewMode
        },
        {
            headerName: 'Augusztus',
            field: '8',
            width: 100,
            renderCell: params => thousandSeparator(params.row['8']) + ' ' + selectedViewMode
        },
        {
            headerName: 'Szeptember',
            field: '9',
            width: 100,
            renderCell: params => thousandSeparator(params.row['9']) + ' ' + selectedViewMode
        },
        {
            headerName: 'Október',
            field: '10',
            width: 100,
            renderCell: params => thousandSeparator(params.row['10']) + ' ' + selectedViewMode
        },
        {
            headerName: 'November',
            field: '11',
            width: 100,
            renderCell: params => thousandSeparator(params.row['11']) + ' ' + selectedViewMode
        },
        {
            headerName: 'December',
            field: '12',
            width: 100,
            renderCell: params => thousandSeparator(params.row['12']) + ' ' + selectedViewMode
        },
        {
            headerName: 'Összesen',
            field: 'total',
            width: 100,
            renderCell: params => thousandSeparator(params.row.total) + ' ' + selectedViewMode
        },
    ]

    const salaryColumns = [
        {headerName: 'Bér', field: 'salary_month', renderCell: params => getMonthNameByNumber(params.row.salary_month)},
        {
            headerName: 'Jutalék',
            field: 'commission_month',
            renderCell: params => getMonthNameByNumber(params.row.commission_month)
        },
        {headerName: 'Munkáltató', field: 'employer'},
        {headerName: 'Bejelentett munkaidő', field: 'worktime'},
        {
            headerName: 'Bruttó bér',
            field: 'gross_salary',
            renderCell: params => thousandSeparator(params.row.gross_salary)
        },
        {
            headerName: 'Bejelentett munkaidő alapján nettó bér',
            field: 'net_salary',
            renderCell: params => thousandSeparator(params.row.net_salary)
        },
        {
            headerName: 'Prémium (bruttó)',
            field: 'gross_premium',
            renderCell: params => thousandSeparator(params.row.gross_premium)
        },
        {
            headerName: 'Prémium (nettó)',
            field: 'net_premium',
            renderCell: params => thousandSeparator(params.row.net_premium)
        },
        {
            headerName: 'Munkáltatói járulék',
            field: 'employer_contribution',
            renderCell: params => thousandSeparator(params.row.employer_contribution)
        },
        {
            headerName: 'Munkáltatói költség',
            field: 'employer_expense',
            renderCell: params => thousandSeparator(params.row.employer_expense)
        },
        {
            headerName: 'Távmunka ktg térítés',
            field: 'remote_expense',
            renderCell: params => thousandSeparator(params.row.remote_expense)
        },
        {
            headerName: 'Bónusz módosítók összege',
            field: 'bonus_modifier',
            renderCell: params => thousandSeparator(params.row.bonus_modifier)
        },
        {
            headerName: 'Szép kártya',
            field: 'beautiful_card',
            renderCell: params => thousandSeparator(params.row.beautiful_card)
        },
        {
            headerName: 'Szép kártya adó',
            field: 'beautiful_card_tax',
            renderCell: params => thousandSeparator(params.row.beautiful_card_tax)
        },
        {headerName: 'Kp', field: 'cash', renderCell: params => thousandSeparator(params.row.cash)},
        {headerName: 'Ügyelet', field: 'duty', renderCell: params => thousandSeparator(params.row.duty)},
        {headerName: 'Napi díj', field: 'daily_fee', renderCell: params => thousandSeparator(params.row.daily_fee)},
        {
            headerName: 'Görgetés levonás',
            field: 'previous_deduction',
            renderCell: params => thousandSeparator(params.row.previous_deduction)
        },
        {
            headerName: 'Jutalék alap',
            field: 'commission_base',
            renderCell: params => thousandSeparator(params.row.commission_base)
        },
        {
            headerName: 'Jutalék 25%',
            field: 'commission_percent',
            renderCell: params => thousandSeparator(params.row.commission_percent)
        },
        {
            headerName: 'Bruttó jutalék',
            field: 'gross_commission',
            renderCell: params => thousandSeparator(params.row.gross_commission)
        },
        {
            headerName: 'Jutalék szorzó',
            field: 'commission_multiplier',
            renderCell: params => thousandSeparator(params.row.commission_multiplier)
        },
        {
            headerName: 'Nettó jutalék',
            field: 'net_commission',
            renderCell: params => thousandSeparator(params.row.net_commission)
        },
        {
            headerName: 'Teljes nettó kereset',
            field: 'total_net_salary',
            renderCell: params => thousandSeparator(params.row.total_net_salary)
        },
        // {headerName: 'Görgetett rész', field: 'previous', renderCell: params => thousandSeparator(params.row.previous)},
        {
            headerName: 'Teljes munkáltatói költség',
            field: 'total_employer_cost',
            renderCell: params => thousandSeparator(params.row.total_employer_cost) + ' Ft'
        },
        {
            headerName: 'Ktg/Árrés aránya',
            field: 'expense_margin_ratio',
            renderCell: params => params.row.expense_margin_ratio + ' %'
        },
        {
            headerName: 'Fix jövedelem/Nettó jövedelem aránya',
            field: 'fix_net_ratio',
            renderCell: params => params.row.fix_net_ratio + ' %'
        },
        {
            headerName: 'Mozgó jövedelem/Nettó jövedelem aránya',
            field: 'moving_net_ratio',
            renderCell: params => params.row.moving_net_ratio + ' %'
        },
    ]

    const months = {
        'Január': '1',
        'Február': '2',
        'Március': '3',
        'Április': '4',
        'Május': '5',
        'Június': '6',
        'Július': '7',
        'Augusztus': '8',
        'Szeptember': '9',
        'Október': '10',
        'November': '11',
        'December': '12'
    }

    const getMonthNameByNumber = (monthNumber) => {
        for (const [monthName, number] of Object.entries(months)) {
            if (number == monthNumber) {
                return monthName;
            }
        }
        if (monthNumber == 0) return 'December'
        return null; // Return null if the month number is not found
    }

    const filterOptions = createFilterOptions({
        ignoreCase: true,
        matchFrom: "start",
        limit: 100,
    })

    const sumForAllClassesByMonth = (month) => {
        const thisMonthsData = data?.map(d => d[month] || 0)
        let dataSum = 0
        thisMonthsData?.forEach(v => dataSum += v)
        return parseInt(dataSum)
    }

    const workTimeClass = (p) => {
        const expectedWorkHours = p.row.ExpectedTime * 24;
        if (p.row.WorkTime * expectedWorkHours > expectedWorkHours) return 'positive'
        if (p.row.WorkTime * expectedWorkHours < expectedWorkHours * 0.6) return 'negative'
        return ''
    }
    const productiveTimeClass = (p) => {
        if (p.row.ProductiveTime > 0.90) return 'positive'
        if (p.row.ProductiveTime < 0.40) return 'negative'
        return ''
    }
    const mouseClass = (p) => {
        if (p.row.keyAndMouseActivity > 40) return 'positive'
        if (p.row.keyAndMouseActivity < 20) return 'negative'
        return ''
    }

    return <AuthDrawer label={user.name}>

        <Stack marginBottom={3} className='gradient-card'>
            <Typography
                variant='headline6'>Összesítő {selectedYear} évhez {selectedPartner?.id && `| ${selectedPartner.uf_name}`}</Typography>
            <Grid container spacing={2} marginTop={2}>
                {Object.keys(months).map(m => <Grid key={m} item xs={2} xl={2}>
                    <Typography variant='overline'>{m}</Typography>
                    <Typography
                        variant='subtitle2'>{thousandSeparator(sumForAllClassesByMonth(months[m]))} {selectedViewMode}</Typography>
                </Grid>)}
            </Grid>
        </Stack>
        <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 2,
            gap: 3,
            marginTop: 3
        }}>
            <Box sx={{
                display: 'flex',
                gap: 3,
            }}>
                {/*<Button variant='outlined' startIcon={showModifiers ? <VisibilityOffRoundedIcon/> : <VisibilityRoundedIcon/>} onClick={() => setShowModifiers(prev => !prev)}>Vonal alatti tételek {showModifiers ? 'elrejtése' : 'mutatása'}</Button>*/}
                {/*<Button variant='outlined' startIcon={<AddCircleRoundedIcon/>} onClick={() => setModifierNewOpen(true)}>Vonal alatti tétel hozzáadása</Button>*/}
            </Box>
            <Box sx={{
                display: 'flex',
                gap: 3,
            }}>
                <FormControl sx={{height: '100%'}}>
                    <Autocomplete
                        disablePortal
                        id="partner-select"
                        filterOptions={filterOptions}
                        options={partners}
                        value={selectedPartner}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => setSelectedPartner(newValue)}
                        inputValue={partnerInput}
                        onInputChange={(event, newInputValue) => setPartnerInput(newInputValue)}
                        getOptionLabel={option => option?.uf_name || 'None'}
                        sx={{width: 300, height: '100%'}}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </FormControl>
                <FormControl>
                    <Select
                        id="margin-traffic-select"
                        value={selectedMode}
                        onChange={e => setSelectedMode(e.target.value)}
                    >
                        <MenuItem value='margin'>Árrés</MenuItem>
                        <MenuItem value='traffic'>Forgalom</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <Select
                        id="money-pcs-select"
                        value={selectedViewMode}
                        onChange={e => setSelectedViewMode(e.target.value)}
                    >
                        <MenuItem value='Ft'>Érték</MenuItem>
                        <MenuItem value='db'>Darabszám</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <Select
                        id="month-select"
                        value={selectedYear}
                        onChange={e => setSelectedYear(e.target.value)}
                    >
                        {yearList.map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
        </Box>
        <DinoDataGrid rows={data} columns={columns}/>
        <Box marginBottom={2} marginTop={2}>
            <Button onClick={() => setModDialogOpen(true)} startIcon={<AddCircleRoundedIcon/>} variant='outlined'>Modosító
                hozzáadása</Button>
        </Box>
        <DinoDataGrid rows={fixedData} columns={salaryColumns}/>
        <Box className="white-card" marginTop={2}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant='headline6'>Üzletági bontás</Typography>
                <Stack spacing={1}>
                    <Typography variant='overline'>Hónap</Typography>
                    <FormControl>
                        <Select
                            id="mod-month-select"
                            variant='standard'
                            value={selectedSectorMonth}
                            name='month'
                            onChange={e => setSelectedSectorMonth(e.target.value)}
                        >
                            <MenuItem value={-1}>Egész év</MenuItem>
                            {Object.entries(months).map((k, v) => <MenuItem key={`m_${k[1]}_m`}
                                                                            value={k[1]}>{k[0]}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Stack>
            </Stack>
            {Object.keys(sectorData).map(ok => <ExpandSector name={ok} data={sectorData[ok]}/>)}
        </Box>
        <Box className="white-card" marginTop={2}>
            <Typography variant='headline6'>Top 10 ügyfél</Typography>
            {/*{JSON.stringify(chartData)}*/}
            <MyPieChart data={chartData}/>
            <DinoDataGrid rows={chartData} columns={[{headerName: 'Ügyfélnév', field: 'uf_name', width: 300}, {
                headerName: 'Százakék',
                field: 'value',
                width: 300,
                renderCell: p => `${p.row?.value} %`
            }]}/>
        </Box>
        <Box className="white-card" marginTop={2}>
            <Typography variant='headline6'>JobCtrl adatok</Typography>
            <Stack spacing={2} marginTop={2}>
                <FormControl>
                    <Select
                        id="job-month-select"
                        // variant='standard'
                        value={selectedJobMonth}
                        name='month'
                        onChange={e => setSelectedJobMonth(e.target.value)}
                    >
                        {Object.entries(months).map((k, v) => <MenuItem key={`m_${k[1]}_m`}
                                                                        value={k[1]}>{k[0]}</MenuItem>)}
                    </Select>
                </FormControl>
                <Stack spacing={1} direction='row' justifyContent='space-between'>
                    <Typography variant='buttonNormal'>Munkaidő egyenleg
                        (Δ%): {Math.round(jobCtrl?.avg_worktime * 100 * 100) / 100} % |
                        ({jobCtrl?.total_time - jobCtrl?.expected_time} h)</Typography>
                    <Typography variant='buttonNormal'>Produktív idő
                        (Σ%): {Math.round(jobCtrl?.avg_productive * 100 * 100) / 100} %</Typography>
                    <Typography variant='buttonNormal'>Offline idő
                        (Δ%): {Math.round(jobCtrl?.avg_offline * 100 * 100) / 100} %</Typography>
                    <Typography variant='buttonNormal'>Billentyűzet- és
                        egéraktivitás: {Math.round(jobCtrl?.avg_keyboard * 100) / 100}</Typography>
                    <Typography variant='buttonNormal'>Elvárt munkaidő: {jobCtrl?.expected_time} h</Typography>
                    <Typography variant='buttonNormal'>Összesen idő: {jobCtrl?.total_time} h</Typography>
                </Stack>
                <DinoDataGrid rows={jobCtrl?.rows || []} columns={[
                    {headerName: 'Nap', field: 'date', width: 100},
                    {
                        headerName: 'Produktív idő',
                        field: 'ProductiveTime',
                        width: 100,
                        cellClassName: p => productiveTimeClass(p),
                        renderCell: p => Math.ceil(p.row.ProductiveTime * 100) + ' %'
                    },
                    {
                        headerName: 'Munkaidő összesen',
                        field: 'WorkTime',
                        width: 100,
                        cellClassName: p => workTimeClass(p),
                        renderCell: p => Math.ceil(p.row.WorkTime * 80) / 10 + ' h'
                    },
                    {
                        headerName: 'Elvárt munkaidő',
                        field: 'ExpectedTime',
                        width: 100,
                        renderCell: p => Math.ceil(p.row.ExpectedTime * 240) / 10 + ' h'
                    },
                    {
                        headerName: 'Billentyűzet és egér',
                        field: 'keyAndMouseActivity',
                        cellClassName: p => mouseClass(p),
                        width: 100,
                        renderCell: p => Math.ceil(p.row.keyAndMouseActivity * 100) / 100
                    },
                ]}/>
            </Stack>
        </Box>

        <DinoDialog open={modDialogOpen}
                    handleClose={() => setModDialogOpen(false)}
                    title='Modosító'
                    subtitle='Válasszon hónapot a módosításhoz'
                    actions={<Stack direction='row' spacing={2}>
                        <Button variant='outlined' onClick={() => setModDialogOpen(false)}>Mégsem</Button>
                        <Button variant='contained' startIcon={<EditRoundedIcon/>} onClick={() => {
                            editModifier()
                            setModDialogOpen(false)
                        }}>Módosítás</Button>
                    </Stack>}>
            <form ref={modRef}>
                <Stack spacing={2}>
                    <Typography variant='overline'>Hónap</Typography>
                    <FormControl>
                        <Select
                            id="mod-month-select"
                            variant='standard'
                            value={selectedMonth}
                            name='month'
                            onChange={e => setSelectedMonth(e.target.value)}
                        >
                            {Object.entries(months).map((k, v) => <MenuItem key={`m_${k[1]}_m`}
                                                                            value={k[1]}>{k[0]}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Typography variant='overline'>Távmunka</Typography>
                    <TextField variant='standard' name='remote_expense' type='number'
                               value={editedModifier?.remote_expense}
                               onChange={e => setEditedModifier({...editedModifier, remote_expense: e.target.value})}/>
                    <Typography variant='overline'>Ügyelet</Typography>
                    <TextField variant='standard' name='duty' type='number' value={editedModifier?.duty}
                               onChange={e => setEditedModifier({...editedModifier, duty: e.target.value})}/>
                    <Typography variant='overline'>Napi bér</Typography>
                    <TextField variant='standard' name='daily_fee' type='number' value={editedModifier?.daily_fee}
                               onChange={e => setEditedModifier({...editedModifier, daily_fee: e.target.value})}/>
                    <Typography variant='overline'>Görgetés</Typography>
                    <TextField variant='standard' name='previous_deduction' type='number'
                               value={editedModifier?.previous_deduction} onChange={e => setEditedModifier({
                        ...editedModifier,
                        previous_deduction: e.target.value
                    })}/>
                </Stack>
            </form>
        </DinoDialog>

        <Loading isLoading={Object.values(loading).includes(true)}/>
    </AuthDrawer>
}

export default EmployeeEffectivenessDetail