import Base from "./Base";
import Typography from "@mui/material/Typography";
import {InputAdornment, Stack, TextField, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {useContext, useState} from "react";
import AuthContext from "../../context/AuthContext";
import IconButton from "@mui/material/IconButton";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";


const Login = () => {
    const theme = useTheme()
    const {loginUser} = useContext(AuthContext);
    const [data, setData] = useState({email: '', password: ''})
    const [passwordVisible, setPasswordVisible] = useState(false)

    return <Base>
        <div className="login-container">
            <div className="login-card">
                <form onSubmit={e => {
                    e.preventDefault()
                    loginUser(data)
                }}>
                    <Stack spacing={{xs: 2, md: 3, lg: 4}}>
                        <Typography variant="headline6" color={theme.palette.special[500]}>Bejelentkezés</Typography>
                        <Stack spacing={1} marginBottom={2} marginTop={2}>
                            <Typography variant="caption" color={theme.palette.primary[500]}>E-mail cím</Typography>
                            <TextField name="email" variant='standard' value={data?.email} onChange={e => setData({...data, email: e.target.value})}/>
                            <Typography variant="caption" color={theme.palette.primary[500]}>Jelszó</Typography>
                            <TextField name="password" type={passwordVisible ? "text" : "password"} value={data?.password} onChange={e => setData({...data, password: e.target.value})} variant='standard' InputProps={{
                                endAdornment: <InputAdornment position="end"><IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                    edge="end"
                                >
                                    {passwordVisible ? <VisibilityOffRoundedIcon /> : <RemoveRedEyeRoundedIcon />}
                                </IconButton>
                                </InputAdornment>
                            }}/>
                        </Stack>
                        <Button variant="contained" type="submit">Bejelentkezés</Button>
                    </Stack>
                </form>
            </div>
        </div>
    </Base>
}

export default Login